import React, {Component} from "react";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@material-ui/core/Button";
import GameContext from "../../../context/GameContext";
import PropTypes from "prop-types";
import gameContextService from "../../../service/GameContextService";
import _ from "lodash";
import styles from "./PassengerDialog.module.scss";
import FieldIcon from "../FieldIcon/FieldIcon";

class PassengerDialog extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.state = {
            isUpdating: false
        }
    }

    componentDidMount() {
        this.setState({
            isUpdating: true
        }, async () => {
            await gameContextService.updatePassengerList(false);
            this.setState({
                isUpdating: false
            });
        });
    }

    render() {
        const passengerList = this.context.passengers;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleDialogClose}
                aria-labelledby="form-dialog-title"
                fullWidth
                maxWidth={"md"}
            >
                <DialogTitle id="form-dialog-title">Passenger Order</DialogTitle>
                <DialogContent>
                    {this.state.isUpdating ?
                        <CircularProgress size={72} color={"info"}/>
                        :
                        <ol className={styles.list}>
                            {_.map(passengerList, (obj, index) => {
                                const clazz = this.context.currentPlayerId === obj.id ? styles.current_player : "";
                                return <li key={index} className={clazz}>
                                    {this.context.currentPlayerId === obj.id && <FieldIcon icon={"caret-right"}
                                                                                className={styles.current_player} />}
                                    <span>{index + 1}. </span>
                                    {obj.name}
                                </li>
                            })}
                        </ol>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.handleDialogClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

PassengerDialog.propTypes = {
    open: PropTypes.bool,
    handleDialogClose: PropTypes.func.isRequired
}

export default PassengerDialog;