import {GameId, GameState, PlaceAction, PlaceStatus} from "../context/GameProvider";
import _ from "lodash";
import PassengerList from "../pages/PassengerList/PassengerList";
import InGame from "../pages/Game/InGame";
import Toast from "../components/Common/Toast/Toast";
import common_styles from "../components/Common/common.module.scss";
import React from "react";
import * as cuisine_images from "../cuisine_icons";
import User from "../User";
import {ANIMATE} from "../Animate";

class GameContextService {
    constructor() {
        this.websocket = undefined;
    }

    /**
     * Sets the GameContext for this service
     * @param gameContext a valid GameContext
     */
    setContext = (gameContext) => {
        this.context = gameContext;
    }

    /**
     * adds a timeout to the "fetch" call
     * @param resource the resource to load
     * @param options additional options
     * @returns {Promise<Response>}
     */
    fetchWithTimeout = async (resource, options = {}) => {
        const { timeout = 10000 } = options;

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);
        let response = undefined;

        try {
            response = await fetch(resource, {
                ...options,
                signal: controller.signal
            });
        } catch(error) {
            console.log("Error fetching: " + error);
        }

        clearTimeout(id);

        return response;
    }

    /**
     * Create a new game via the API
     * @returns {Promise} true if the game was created, false otherwise
     */
    createGame = async () => {
        let gameExists = false;

        const places = this.context.getPlacesRequest();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                game_name: this.context.getTrainName(),
                meeting_time: this.context.getMeetingTime(),
                restaurants: places,
                settings: {
                    turn_duration: 60,
                    bringback_count: 1
                }
            })
        }

        if (this.context.getGameId() !== GameId.UNKNOWN) {
            gameExists = await this.doesGameExist(false);
        }

        let resp = {};
        await this.context.setIsCreatingGame(true);

        try {
            if (!gameExists) {
                resp = await this.fetchWithTimeout('/api/games', requestOptions);
            } else {
                resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}`, requestOptions);
            }
        } catch(error) {
            console.error("Create game request timed out");
        }

        if (resp?.ok) {
            const game = await resp.json();
            console.log("Creating game: " + game.id);
            await this.context.setGameId(game.id);
            await this.context.setGameOwnerId(game.owner);
        } else {
            const message = "Error creating new game";
            await this.context.setGameId(GameId.UNKNOWN);
            this.context.setToastMessage(message);
        }

        await this.context.setIsCreatingGame(false);

        return new Promise((resolve) => {
            resolve(this.context.getGameId() !== GameId.UNKNOWN);
        });
    }

    /**
     * Starts the game via a websocket message to the server
     */
    startGame = () => {
        this.context.clearErrors();

        if(this.isSocketConnected()) {
            this.context.setIsStartingGame(true);
            const message = {
                message_type: CLIENT_MESSAGE.GAME_START
            }

            this.sendMessage(JSON.stringify(message));
        } else {
            this.context.setToastMessage("Error connecting to server. Please try again.");
        }
    }

    /**
     * Returns true if the websocket is connected and OPEN, false otherwise
     * @returns {boolean}
     */
    isSocketConnected = () => {
        return this.websocket !== undefined && this.websocket.readyState === SOCKET.OPEN;
    }

    /**
     * Sends a message via the websocket. If the connection is down, reconnect
     * @param json
     */
    sendMessage = async (json) => {
        try {
            if(this.isSocketConnected()) {
                this.websocket.send(json);
            } else {
                alert("Failed to send action. Please try again.");
            }
        } catch(ex) {
            this.context.setToastMessage("Error sending action. Please try again.");
        }
    }

    getGameId = () => {
        return this.context.getGameId();
    }

    getGameOwnerId = () => {
        return this.context.getGameOwnerId();
    }

    disconnectSocket = () => {
        if(this.isSocketConnected()) {
            console.log("websocket: " + this.websocket);
            console.log("Closing socket");
            this.websocket.close();
        }
    }

    /**
     * Connects to the game's websocket and sets up the listeners
     * @param reconnect true if we are trying to reconnect to the socket
     */
    connectToGame = async (reconnect) => {
        if(this.isSocketConnected() || this.context.getIsConnectingToGame() ||
            this.context.getGameState() === GameState.GAME_OVER) {
            return;
        }

        console.log("Connecting to game: " + this.context.getGameId());

        await this.context.setIsConnectingToGame(true);
        const socket = await new WebSocket(this.getWebsocketUrl());

        const self = this;

        socket.onopen = () => {
            console.log("ws connected");
            this.websocket = socket;
            this.updateGameContext();
            this.context.setIsConnectingToGame(false);
        }

        socket.onclose = (event) => {
            this.context.setIsConnectingToGame(false);
            this.websocket = undefined;
        }

        socket.onmessage = (event) => {
            try {
                const data = JSON.parse(event.data);
                this.handleMessage(data);
            } catch(error) {
                this.context.setToastMessage(error)
            }
        }

        socket.onerror = (event) => {
            this.context.setIsConnectingToGame(false);

            // if there is a websocket error and attempt to connect to game, then perhaps the game is over
            this.updateGameContext();
        }
    }

    /**
     * Build the URL for the websocket, accounting for whether we're connecting over http vs https
     * @returns the URL
     */
    getWebsocketUrl = () => {
        const loc = window.location
        const protocol = loc.protocol === 'https:' ? 'wss' : 'ws'
        let host = loc.host;
        return `${protocol}://${host}/api/games/${this.context.getGameId()}/connect`;
    }

    /**
     * Returns the join link for all passengers
     * @returns {string} the full URL to join the game
     */
    getInvitation = () => {
        const loc = window.location
        const protocol = loc.protocol === 'https:' ? 'https' : 'http'
        let host = loc.host;
        return `You've been invited to hop aboard The Lunch Train! Join here: ${protocol}://${host}/join/${this.context.getGameId()}`;
    }

    /**
     * Removes a passenger from the game given his/her unique id
     * @param id a valid player id
     * @returns {boolean} true if the passenger was removed, false otherwise
     */
    removePassenger = async (id) => {
        let retVal = false;
        await this.context.clearErrors();
        console.log("Deleting passenger: " + id);
        const requestOptions = {
            method: 'DELETE',
        }

        let resp = {};

        try {
            resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}/players/${id}`, requestOptions);
        } catch(error) {
            console.error("removePassenger timed out");
        }

        if(resp.ok) {
            retVal = true;
        } else {
            const json = resp.json ? await resp.json() : {};
            let message = json.message;
            if(!message) {
                message = "Remove passenger failed. Please try again";
            }

            this.context.setToastMessage(message);
        }

        return retVal;
    }

    /**
     * Adds the given passenger to the current game
     * @param userContext a valid UserContext
     * @returns {Promise<void>}
     */
    addPassenger = async(userContext) => {
        await this.context.clearErrors();
        console.log("username: " + userContext.name.trim());
        await userContext.setName(userContext.name.trim());

        const colors = ["blue", "green", "orange", "pink", "purple", "red", "yellow"];
        const color = colors[Math.floor(Math.random() * colors.length)];

        console.log("Adding passenger: " + userContext.name);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: userContext.name.trim(),
                avatar: userContext.role === User.CONDUCTOR ? "conductor" : color
            })
        }

        let resp = {};

        try {
            resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}/players`, requestOptions);
        } catch(error) {
            console.error("addPassenger timed out");
        }

        if(resp.ok) {
            const json = await resp.json();
            console.log(json.name + " / " + json.id + " added");
            userContext.setUserId(json.id);
            userContext.setBringBacks(json.bringbacks_available);
            userContext.role = json.id === this.context.getGameOwnerId() ? User.CONDUCTOR : User.PASSENGER
            console.log(`My user ID: ${json.id}. Owner user ID: ${this.context.getGameOwnerId()}`);
        } else {
            const json = resp.json ? await resp.json() : {};
            let message = json.message;
            if(!message) {
                message = "Add passenger failed. Please try again";
            }

            this.context.setToastMessage(message);
        }
    }

    /**
     * Updates the passenger list within the GameContext
     * @returns {Promise<void>}
     */
    updatePassengerList = async () => {
        await this.context.clearErrors();

        let resp = {};
        try {
            resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}/players`, {method: 'GET'});
        } catch(error) {
            console.error("updatePassengerList timed out");
        }

        if (resp.ok) {
            const json = await resp.json();
            this.context.setPassengers(json.players);
        } else {
            const json = resp.json ? await resp.json() : {};
            let message = "Error updating passenger list";
            if(json.message) {
                message += ": " + json.message
            }

            this.context.setToastMessage(message);
        }
    }

    /**
     * Queries the backend for the restaurants given the desired parameters
     * @param params a Map of parameters ["location", "term"]
     * @returns {Promise<void>} a Promise that is complete when the search is complete and the restaurants have been updated
     */
    searchForPlaces = async (params) => {
        this.context.setIsSearching(true);
        this.context.setSearchResults([]);
        this.context.setDoSortResults(true);
        let errorMessage = undefined;

        const parameters = [];
        let query = `/api/restaurants/search?`;

        if(params.location) {
            parameters.push("location=" + params.location);
        } else if(params.lat && params.long) {
            parameters.push("latitude=" + params.lat);
            parameters.push("longitude=" + params.long);
        }

        if(params.term) {
            parameters.push("term=" + params.term);
            this.context.setDoSortResults(false);
        }

        // number of search results
        parameters.push("rows=50");

        // radius (in meters)
        parameters.push("radius=4800");

        const paramsFormatted = _.join(parameters, "&");
        query += paramsFormatted;

        let resp = {};
        try {
            resp = await this.fetchWithTimeout(query);
        } catch(error) {
            console.error("searchForPlaces timed out");
        }

        if (resp && !resp.ok) {
            errorMessage = "There was a problem running the search. Please try again.";
            this.context.setToastMessage(errorMessage);
        } else {
            const json = resp.json ? await resp.json() : {};
            this.context.setSearchResults(json.restaurants);
        }

        this.context.setIsSearching(false);
    }

    /**
     * Queries the backend to determine if the game represented by the current gameId exists
     * @param displayError true if we are to display a user-facing error, false otherwise
     * @returns {Promise<boolean>}
     */
    doesGameExist = async (displayError) => {
        let retVal = false;
        let resp = {};

        try {
            if(this.context.getGameId() !== GameId.UNKNOWN) {
                resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}`, {method: 'GET'});
            }
        } catch(error) {
            console.error("doesGameExist request timed out");
        }

        if(resp.ok) {
            retVal = true;
        } else {
            if(displayError && resp.json) {
                const json = await resp.json();
                this.context.setToastMessage(json.message);
            }
        }

        return retVal;
    }

    /**
     * Checks if the current gameId exists and then adds the passenger to the train
     * @param userContext a valid UserContext
     */
    boardTrain = async (userContext) => {
        await this.context.setIsBoarding(true);

        const gameExists = await this.doesGameExist(true);
        if(gameExists) {
            await this.connectToGame();
            await this.addPassenger(userContext);

            // check game state
            let resp = {};

            try {
                resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}`, {method: 'GET'});
            } catch(error) {
                console.error("boardTrain request timed out");
            }

            if(resp.ok) {
                const json = await resp.json();
                if(json.gameplay) {
                    this.joinGame(json.gameplay);
                } else {
                    this.context.setDisplayPage(<PassengerList />);
                }
            } else {
                const json = resp.json ? await resp.json() : {};
                let message = json.message;
                if(!message) {
                    message = "Error boarding train. Please try again";
                }
                this.context.setToastMessage(message);
            }
        }

        await this.context.setIsBoarding(false);
    }

    /**
     * Eliminates a place from the current game
     * @param id a valid restaurant id
     * @returns {Promise<void>}
     */
    eliminatePlaceInGame = async (id) => {
        await this.context.setIsPlayerAction(true);
        await this.context.clearErrors();

        const message = {
            message_type: CLIENT_MESSAGE.PLAYER_MOVE,
            restaurant_id: id,
            action: PlaceAction.ELIMINATE
        }
        this.sendMessage(JSON.stringify(message));
    }

    /**
     * Brings back a place from the current game
     * @param id a valid restaurant id
     * @returns {Promise<void>}
     */
    bringBackPlace = async (id) => {
        await this.context.setIsPlayerAction(true);
        await this.context.clearErrors();
        console.log("Bringing back place: " + id);

        const message = {
            message_type: CLIENT_MESSAGE.PLAYER_MOVE,
            restaurant_id: id,
            action: PlaceAction.BRING_BACK
        }
        this.sendMessage(JSON.stringify(message));
    }

    /**
     * Returns a JSON object to be used to display a Toast to the end user
     * @param json a valid "last_turn_result"
     * @param json.player_id a valid player id
     * @param json.restaurant_id a valid restaurant id
     * @param json.action a valid PlaceAction
     * @returns {{}}
     */
    getLastTurnValues = (json) => {
        let retVal = {};

        if(json.player_id) {
            retVal.name = this.context.getPassengerName(json.player_id);
        }

        if(json.restaurant_id) {
            retVal.place = this.context.getPlaceName(json.restaurant_id);
        }

        const verb = {
            [PlaceAction.BRING_BACK]: "brought back",
            [PlaceAction.ELIMINATE]: "removed"
        }

        if(json.action) {
            retVal.action = verb[json.action];
        }

        return retVal;
    }

    /**
     * Handles a valid player move
     * @param data a valid PLAYER_MOVE_MADE JSON object
     * @param data.last_turn_result the last_turn_result block from the backend
     * @param data.current_player_id the id of the player whose turn it now is
     */
    handlePlayerMove = (data) => {
        if(data.last_turn_result) {
            this.context.updatePlaceStates(data.last_turn_result);
            if(data.current_player_id) {
                this.context.setCurrentPlayerId(data.current_player_id);
            }

            const toastObj = this.getLastTurnValues(data.last_turn_result);
            const placesInPlay = _.filter(this.context.getPlaces(), {"status": PlaceStatus.ACTIVE}).length;
            if(toastObj.name && toastObj.place && toastObj.action && placesInPlay >= 2) {
                const message = `${toastObj.name} has ${toastObj.action} '${toastObj.place}'`;
                const severity = data.last_turn_result.action === PlaceAction.ELIMINATE ? Toast.Severity.ERROR : Toast.Severity.SUCCESS;
                this.context.setToastMessage(message, severity, 3000);
            }
        } else {
            this.context.setToastMessage("Player move response not expected: ");
            // this.context.prettyPrintJson(data, "Player move");
        }
    }

    /**
     * Joins a game currently in progress
     * @param data a json.gameplay object
     * @param data.current_player_id the id of the player whose turn it now is
     * @param data.turn_start_time a valid string timestamp
     * @param data.turn_end_time a valid string timestamp
     */
    joinGame = (data) => {
        this.context.setDoSortResults(true);
        this.context.setIsStartingGame(false);
        this.context.clearErrors();
        this.context.setGameState(GameState.IN_GAME);
        this.context.setCurrentPlayerId(data.current_player_id);
        this.context.setSearchResults([]);
        this.updatePlayerTimer(data.turn_start_time, data.turn_end_time);
        this.context.setDisplayPage(<InGame />);
    }

    /**
     * Updates the player time to use the duration specified by end - start time
     * @param start the turn start
     * @param end the turn end
     */
    updatePlayerTimer = (start, end) => {
        try {
            const timeStart = new Date(start).getTime();
            const timeEnd = new Date(end).getTime();
            const durationSeconds = Math.trunc((timeEnd - timeStart) / 1000);

            this.context.startPlayerTimer(durationSeconds);
        } catch(ex) {
            console.error("Error creating player timer");
            console.error(ex.message);
        }
    }

    /**
     * Handles a given player running out of time to make an action
     * @param data
     * @param data.timed_out_player_id the id of the player whose turn
     * @param data.current_player_id the id of the player whose turn it now is
     */
    handlePlayerOutOfTime = (data) => {
        const player = this.context.getPassengerName(data.timed_out_player_id);
        this.context.setToastMessage(`${player} ran out of time`, Toast.Severity.WARNING, 3000);
        this.context.setCurrentPlayerId(data.current_player_id);
    }

    /**
     * Handles the Game Over action
     * @param id a valid restaurant id
     */
    handleGameOver = (id) => {
        console.log("Game over: " + id);
        const place = _.find(this.context.getPlaces(), {id: id});
        if(place) {
            this.context.setGameState(GameState.GAME_OVER);
            this.context.setWinningPlace(place);
            this.context.setVisiblePlaces([]);
            this.context.setToastMessage(Toast.MessageType.FEEDBACK, Toast.Severity.INFO, 30000);
        } else {
            console.error("Restaurant with id " + id + " does not exist");
        }

        this.context.startPlayerTimer(0);
    }

    handleGameState = (data) => {
        console.log("Updating state with", data.game);
        this.updateGameContextFromState(data.game);
    }

    /**
     * Handles a player leaving the game
     * @param data
     * @param data.player_id a valid player id within the current game
     */
    handlePlayerConnectionChange = (data) => {
        const player = this.context.getPassengerName(data.player_id);

        if(player === "Unknown") {
            console.log("Player name was unknown");
            return;
        }

        if(this.context.gameState !== GameState.IN_GAME && this.context.gameState !== GameState.GAME_OVER) {
            switch (data.connection_state) {
                case "OFFLINE":
                    this.context.setToastMessage(`${player} has disconnected`, Toast.Severity.WARNING, 5000);
                    break;
                case "ONLINE":
                    this.context.setToastMessage(`${player} has joined the game`, Toast.Severity.WARNING, 5000);
                    break;
            }
        }

        // update context
        this.updateGameContext();
    }

    /**
     * This currently means a user has left the game and has come back, potentially with a name change
     * @param data
     * @param data.player a player JSON block containing an "id" param
     */
    handlePlayerUpdated = (data) => {
        const player = this.context.getPassengerName(data.player.id);
        let message = `${player} has returned to the game`;
        this.context.setToastMessage(message, Toast.Severity.SUCCESS, 3000);
    }

    /**
     * Updates the Passenger List after a player is removed
     * @param data
     * @param data.player a player JSON block containing an "id" param
     */
    handlePlayerRemoved = (data) => {
        this.context.setPlayerRemoved(data.player.id);
    }

    /**
     * Handles the message received from the server
     * @param data a valid JSON object
     * @param data.message_type a message_type from the backend
     * @param data.winning_restaurant_id a valid restaurant id within the current game
     * @param data.turn_start_time a valid string timestamp
     * @param data.turn_end_time a valid string timestamp
     */
    handleMessage = (data) => {
        // this.context.prettyPrintJson(data, "handleMessage");
        switch(data.message_type) {
            case SERVER_MESSAGE.GAME_OVER:
                this.handleGameOver(data.winning_restaurant_id);
                break;
            case SERVER_MESSAGE.GAME_STATE:
                this.handleGameState(data);
                break;
            case SERVER_MESSAGE.PLAYER_CONNECTION_CHANGE:
                this.handlePlayerConnectionChange(data);
                break;
            case SERVER_MESSAGE.PLAYER_JOINED:
                this.updatePassengerList();
                break;
            case SERVER_MESSAGE.PLAYER_MOVE_MADE:
                this.handlePlayerMove(data);
                this.updatePassengerList();
                break;
            case SERVER_MESSAGE.PLAYER_REMOVED:
                this.handlePlayerRemoved(data);
                break;
            case SERVER_MESSAGE.PLAYER_TURN_OUT_OF_TIME:
                this.handlePlayerOutOfTime(data);
                break;
            case SERVER_MESSAGE.PLAYER_TURN_START:
                this.joinGame(data);
                break;
            case SERVER_MESSAGE.PLAYER_UPDATED:
                if(this.context.getGameState() === GameState.IN_GAME) {
                    this.handlePlayerUpdated(data);
                } else {
                    this.updateGameContext();
                }
                break;
            default:
                console.log("No handler for: " + data.message_type);
        }

        // update player timer
        if(data.current_server_time && data.turn_end_time) {
            this.updatePlayerTimer(data.current_server_time, data.turn_end_time);
        }
    }

    /**
     * Returns the given text as "train" text
     * @param text valid text
     * @returns {JSX.Element} a React Component
     */
    getTrainText = (text) => {
        const convert = text + "";
        const chars = [];
        for(let i = 0; i < convert.length; i++) {
            chars.push(convert.charAt(i));
        }

        return (
            <div className={common_styles.train_text}>
                {[...chars].map((char, index) => {
                    return <div key={`${char}_${index}`}>
                        {char}
                    </div>
                })}
            </div>
        );
    }

    /**
     * @returns {string} the meeting time converted to user's local time
     */
    getMeetingTime = () => {
        return new Date(this.context.getMeetingTime()).toLocaleString().replace(/:[\d][\d] /, " ");
    }

    /**
     * Returns the cuisine image given the category
     * @param category an array of categories from the backend
     * @returns {*} the cuisine_image[x] value
     */
    getPlaceIcon = (category) => {
        const cat = category ? category[0].toLowerCase() : "generic";
        let icon = cuisine_images["generic"];

        switch(cat) {
            case "american":
            case "american (new)":
            case "american (traditional)":
                icon = cuisine_images["american"];
                break;
            case "breakfast":
            case "breakfast & brunch":
                icon = cuisine_images["breakfast"];
                break;
            case "asian":
            case "asian fusion":
            case "shanghainese":
            case "szechuan":
            case "thai":
                icon = cuisine_images["noodles"];
                break;
            case "bakery":
            case "bakeries":
            case "patisserie":
            case "patisserie/cake shop":
                icon = cuisine_images["bakery"];
                break;
            case "bbq":
            case "barbeque":
                icon = cuisine_images["bbq"];
                break;
            case "bars":
            case "beer":
            case "beer bar":
            case "beer bars":
            case "brewpubs":
            case "gastropubs":
            case "gay bars":
            case "pubs":
            case "sports bars":
                icon = cuisine_images["beer"];
                break;
            case "burgers":
            case "diners":
            case "fast food":
                icon = cuisine_images["burgers"];
                break;
            case "cajun":
            case "cajun/creole":
            case "seafood":
                icon = cuisine_images["seafood"];
                break;
            case "champagne bars":
                icon = cuisine_images["champagne"];
                break;
            case "chinese":
                icon = cuisine_images["chinese"];
                break;
            case "cocktail bars":
            case "lounges":
            case "tiki bars":
                icon = cuisine_images["cocktails"];
                break;
            case "coffee":
            case "coffee & tea":
            case "tea":
            case "tea rooms":
                icon = cuisine_images["coffee"];
                break;
            case "creperies":
                icon = cuisine_images[cat];
                break;
            case "dance clubs":
                icon = cuisine_images["dance"];
                break;
            case "deli":
            case "delis":
            case "cafes":
                icon = cuisine_images["delis"];
                break;
            case "dessert":
            case "desserts":
            case "ice cream":
            case "ice cream & frozen yogurt":
                icon = cuisine_images["dessert"];
                break;
            case "donut":
            case "donuts":
                icon = cuisine_images["donuts"];
                break;
            case "european":
            case "modern european":
                icon = cuisine_images["european"];
                break;
            case "filipino":
                icon = cuisine_images["filipino"];
                break;
            case "food truck":
            case "food trucks":
                icon = cuisine_images["food_truck"];
                break;
            case "french":
            case "brasserie":
                icon = cuisine_images["french"];
                break;
            case "german":
                icon = cuisine_images[cat];
                break;
            case "greek":
                icon = cuisine_images[cat];
                break;
            case "halal":
            case "afghan":
            case "ethiopian":
            case "lebanese":
            case "middle eastern":
            case "pakistani":
            case "persian/iranian":
                icon = cuisine_images["halal"];
                break;
            case "hot dogs":
                icon = cuisine_images[cat];
                break;
            case "indian":
                icon = cuisine_images[cat];
                break;
            case "irish":
            case "irish pub":
                icon = cuisine_images["irish"];
                break;
            case "italian":
                icon = cuisine_images[cat];
                break;
            case "japanese":
            case "sushi":
            case "sushi bars":
                icon = cuisine_images["japanese"];
                break;
            case "korean":
                icon = cuisine_images["korean"];
                break;
            case "mediterranean":
                icon = cuisine_images[cat];
                break;
            case "noodles":
                icon = cuisine_images["noodles"];
                break;
            case "mexican":
            case "tacos":
                icon = cuisine_images["tacos"];
                break;
            case "music venues":
            case "jazz & blues":
                icon = cuisine_images["guitar"];
                break;
            case "latin american":
            case "peruvian":
            case "spanish":
                icon = cuisine_images["hispanic"];
                break;
            case "pizza":
                icon = cuisine_images[cat];
                break;
            case "ramen":
                icon = cuisine_images[cat];
                break;
            case "russian":
                icon = cuisine_images[cat];
                break;
            case "salad":
                icon = cuisine_images[cat];
                break;
            case "sandwich":
            case "sandwiches":
                icon = cuisine_images["sandwiches"];
                break;
            case "soup":
            case "soups":
                icon = cuisine_images["soup"];
                break;
            case "steakhouse":
            case "steakhouses":
                icon = cuisine_images["steak"];
                break;
            case "turkish":
                icon = cuisine_images[cat];
                break;
            case "vietnamese":
                icon = cuisine_images[cat];
                break;
            case "wine bars":
            case "wine & spirits":
            case "wineries":
                icon = cuisine_images["wine"];
                break;
            default:
                icon = cuisine_images["generic"];
        }

        if(icon === cuisine_images["generic"] && category.length > 1) {
            return this.getPlaceIcon(category.slice(1));
        }

        return icon;
    }

    /**
     * Opens the given url in a new tab
     * @param url a valid URL
     */
    openUrl = (url) => {
        if(url) {
            if(window.isNativeApp) {
                window.dispatchEvent(new CustomEvent("tlt_open_url", {"detail": {url}}));
            } else {
                window.open(url, "_blank");
            }
        }
    }

    /**
     * @returns {JSX.Element} the current JSX page object
     */
    getDisplayPage = () => {
        return this.context.getDisplayPage();
    }

    /**
     * Animates the block header titles
     */
    animateHeader = (element) => {
        if(element) {
            element.classList.add(ANIMATE.ANIMATED);
            element.classList.add(ANIMATE.BOUNCE_IN_RIGHT);
            element.addEventListener(ANIMATE.EVENT_ANIMATION_END, () => {
                element.classList.remove(ANIMATE.ANIMATED);
                element.classList.remove(ANIMATE.BOUNCE_IN_RIGHT);
            });
        }
    }

    /**
     * Updates the current GameContext from the backend
     * @returns {Promise<void>}
     */
    updateGameContext = async() => {
        await this.context.setIsPlayerAction(false);
        this.context.clearErrors();
        console.log("Updating game: " + this.context.getGameId());

        const gameExists = await this.doesGameExist(true);
        if(gameExists) {
            let resp = {};
            try {
                resp = await this.fetchWithTimeout(`/api/games/${this.context.getGameId()}`);
            } catch(error) {
                console.error("udpateGameContext timed out");
            }

            if (resp.ok) {
                const json = await resp.json();
                this.updateGameContextFromState(json);
            } else {
                const json = resp.json ? await resp.json() : {};
                let message = json.message;
                if(!message) {
                    message = "Unable to update the game";
                }

                this.context.setToastMessage(message);
            }
        }
    }

    /**
     * Updates the local game context based on the given JSON state
     * @param state JSON representing the current game state
     */
    updateGameContextFromState = (state) => {
        // this.context.prettyPrintJson(state);
        this.context.setTrainName(state.name);
        this.context.setPassengers(state.players);
        this.context.setPlacesInPlay(state.restaurants);
        this.context.setMeetingTime(state.meeting_time);
        const current_player_id = state.current_player_id ? state.current_player_id : state.gameplay?.current_player_id;
        this.context.setCurrentPlayerId(current_player_id);
        this.context.setGameOwnerId(state.owner);

        let gameState = GameState.NOT_STARTED;
        if (state.gameplay) {
            const isOver = _.filter(state.restaurants, (r) => r.status === 'ACTIVE').length === 1;
            gameState = isOver ? GameState.GAME_OVER : GameState.IN_GAME;

            const place_id = state.gameplay.winning_restaurant_id;
            if(place_id) {
                const place = _.find(this.context.getPlaces(), {id: place_id});
                this.context.setWinningPlace(place);
            }
        }

        this.context.setGameState(gameState);

        if (gameState === GameState.IN_GAME) {
            // Make sure all restaurant states are up to date
            this.context.setVisiblePlaces(state.restaurants)

            // enter the game if necessary
            const DISPLAY_PAGE = this.getDisplayPage();
            if(DISPLAY_PAGE.props.displayName === "PassengerList") {
                this.joinGame(state.gameplay);
            }

            // update player timer
            if(state.gameplay?.current_server_time && state.gameplay?.turn_end_time) {
                this.updatePlayerTimer(state.gameplay.current_server_time, state.gameplay.turn_end_time);
            }
        }
    }
}

export const SERVER_MESSAGE = {
    GAME_OVER: "GAME_OVER",
    GAME_STATE: "GAME_STATE",
    PLAYER_CONNECTION_CHANGE: "PLAYER_CONNECTION_CHANGE",
    PLAYER_JOINED: "PLAYER_JOINED",
    PLAYER_MOVE_MADE: "PLAYER_MOVE_MADE",
    PLAYER_REMOVED: "PLAYER_REMOVED",
    PLAYER_TURN_OUT_OF_TIME: "PLAYER_TURN_OUT_OF_TIME",
    PLAYER_TURN_START: "PLAYER_TURN_START",
    PLAYER_UPDATED: "PLAYER_UPDATED"
}

export const CLIENT_MESSAGE = {
    PLAYER_MOVE: "PLAYER_MOVE",
    PLAYER_UPDATE: "PLAYER_UPDATE",
    GAME_START: "GAME_START"
}

const SOCKET = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3
}

const gameContextService = new GameContextService();
export default gameContextService;