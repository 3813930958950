import GameContext from "./GameContext";
import {Component} from "react";
import {FILTER_DISTANCE, FILTER_PRICE} from "../components/Common/FilterDialog/FilterDialog";
import _, {remove} from "lodash";
import gameContextService from "../service/GameContextService";
import Toast from "../components/Common/Toast/Toast";
import * as Events from "events";
import HomePage from "../pages/HomePage/HomePage";

class GameProvider extends Component {
    static GAME_ID_LENGTH = 4;
    static MIN_TRAIN_NAME_LENGTH = 3;
    static MAX_TRAIN_NAME_LENGTH = 16;

    constructor(props) {
        super(props);
        gameContextService.setContext(this);

        // the Timer object for each turn
        this.playerTimer = undefined;
    }

    state = {
        // unique id of this Component
        id: _.uniqueId("GameProvider-"),

        // remaining yelp requests
        canQueryYelp: true,

        // the page to display
        displayPage: <HomePage />,

        // state of Game
        currentPlayerId: -1,
        gameState: GameState.NOT_STARTED,
        gameId: GameId.UNKNOWN,
        gameOwnerId: undefined,

        // toast message
        toastMessage: undefined,
        toastSeverity: undefined,
        toastDuration: Toast.DEFAULT_DURATION,

        // true if we are connecting to the game, false otherwise
        isConnectingToGame: false,
        // true if we are creating the game, false otherwise
        isCreatingGame: false,
        // true if we are starting the game, false otherwise
        isStartingGame: false,
        // used to display searching spinner
        isSearching: false,
        // used to display boarding spinner
        isBoarding: false,
        // true if we are updating game state, false otherwise
        isUpdating: false,

        // true if the player is taking an action, false otherwise
        isPlayerAction: false,

        // places that will be part of the game
        placesInPlay: [],
        placeChanged: "",

        // search criteria
        doSortResults: true,
        searchLocation: "",
        searchResults: [],
        searchValue: "",

        // filter criteria
        filterCuisines: [],
        filterDistance: FILTER_DISTANCE.DEFAULT,
        filterPrice: FILTER_PRICE.ALL_PRICES,

        // name of Game Train
        trainName: "The Lunch Train",

        // passenger list
        passengers: [],

        // websocket
        websocket: undefined,

        // player timer
        turnDuration: undefined,

        // winning restaurant object
        winningRestaurant: undefined,

        // the player to remove
        removePlayerId: undefined,

        // the places visible to user
        visiblePlaces: [],

        // the meet up time
        meetingTime: new Date(),

        // role of display (mobile, desktop)
        display: undefined,
    }

    /**
     * Pretty prints the given JSON with an optional pre-message
     * @param json valid JSON
     * @param message (optional) pre-message
     */
    prettyPrintJson = (json, message) => {
        const jsonPretty = JSON.stringify(JSON.parse(JSON.stringify(json)),null,2);
        console.log(message + ": " + jsonPretty);
    }

    /**
     * Removes any errors from the current GameContext
     */
    clearErrors = () => {
        this.setState({
            error: undefined
        });
    }

    /**
     * @returns {JSX.Element} the current JSX page object
     */
    getDisplayPage = () => {
        return this.state.displayPage;
    }

    /**
     * Sets the currently visible React Component
     * @param page a valid Component
     */
    setDisplayPage = (page) => {
        const self = this;
        this.setState({
            displayPage: page
        }, () => {
            if(self.state.displayPage.props.displayName === "HomePage") {
                self.updateYelpStatus();
            }
        });
    }

    /**
     * Modifies the searchResults state
     * @param newPlaces an array of places to display within the search results
     */
    setSearchResults = (newPlaces) => {
        this.setState({
            searchResults: newPlaces ? newPlaces : [],
            visiblePlaces: newPlaces ? newPlaces : []
        });
    }

    /**
     * Updates the doSortResults state
     * @param doSortResults true if we are to sort places, false otherwise
     */
    setDoSortResults = async (doSortResults) => {
        this.setState({
            doSortResults: doSortResults
        });
    }

    /**
     * Sets the Train name given a text field
     * @param event the input field containing the name
     */
    setTrainNameFromEvent = (event) => {
        if(event.target.value.trim().length <= GameProvider.MAX_TRAIN_NAME_LENGTH) {
            this.setState({
                trainName: event.target.value
            });
        }
    }

    /**
     * Sets the Train name
     * @param name the name to set
     */
    setTrainName = (name) => {
        return this.setState({
            trainName: name
        });
    }

    /**
     * Sets the state of the search
     * @param isSearching true if we are searching, false otherwise
     */
    setIsSearching = (isSearching) => {
        this.setState({
            isSearching: isSearching
        });
    }

    // unused at this time
    setFilterPrice = (event) => {
        this.setState( {
            filterPrice: event.target.value.trim()
        });
    }

    // unused at this time
    setFilterDistance = (event) => {
        this.setState( {
            filterDistance: event.target.value.trim()
        });
    }

    /**
     * Sets the Game ID to join
     * @param event a valid textfield
     */
    setGameIdFromEvent = (event) => {
        if(event.target.value.trim().length <= GameProvider.GAME_ID_LENGTH) {
            this.setState({
                gameId: event.target.value.trim().toUpperCase()
            });
        }
    }

    /**
     * Sets the Game ID to join
     * @param id a valid game id
     */
    setGameId = async (id) => {
        return this.setState({
            gameId: id ? id.toUpperCase() : GameId.UNKNOWN
        }, () => {
            if(this.state.gameId.length !== GameProvider.GAME_ID_LENGTH && this.state.gameId !== GameId.UNKNOWN) {
                this.setToastMessage(`Game ID must be ${GameProvider.GAME_ID_LENGTH} alphanumeric characters`);
            }
        });
    }

    /**
     * Sets the Game Owner ID (Player ID that owns the game)
     * @param id a valid player ID
     */
    setGameOwnerId = async (id) => {
        return this.setState({
            gameOwnerId: id
        });
    }

    /**
     * Sets the GameState for the current game
     * @param state the desired GameState
     * @returns {Promise<void>}
     */
    setGameState = async (state) => {
        return this.setState({
            gameState: state
        });
    }

    /**
     * @returns {string} the GameContext game id
     */
    getGameId = () => {
        return this.state.gameId;
    }

    getGameOwnerId = () => {
        return this.state.gameOwnerId;
    }

    /**
     * @returns {string} the current GameState
     */
    getGameState = () => {
        return this.state.gameState;
    }

    /**
     * @returns {string} the GameContext places in play
     */
    getPlaces = () => {
        return this.state.placesInPlay;
    }

    /**
     * @returns {string} the GameContext places in play JSON values request for the backend
     */
    getPlacesRequest = () => {
        const array = [];
        _.forEach(this.state.placesInPlay, function(obj) {
           array.push({
               id: obj.id,
               category: obj.category,
               name: obj.name,
               rating: obj.rating,
               price: obj.price,
               location: obj.location,
               url: obj.url
           });
        });

        return array;
    }

    /**
     * Returns the passenger name given a valid passenger id
     * @param id a valid passenger id
     * @returns {string} the passenger name corresponding to the given id
     */
    getPassengerName = (id) => {
        let retVal = "Unknown";
        const passenger = _.find(this.state.passengers, {id: id});
        if(passenger) {
            retVal = passenger.name;
        }

        return retVal;
    }

    /**
     * Returns the place name given a valid id
     * @param id a valid id
     * @returns {string} the place name corresponding to the given id
     */
    getPlaceName = (id) => {
        let retVal = "Unknown";
        const place = _.find(this.state.placesInPlay, {id: id});
        if(place) {
            retVal = place.name;
        }

        return retVal;
    }

    /**
     * @returns {string} the GameContext game train name
     */
    getTrainName = () => {
        return this.state.trainName;
    }

    /**
     * Updates the Context to contain the latest message which should be displayed to the user
     * @param message the desired message to display
     * @param severity a Toast.Severity level
     * @param duration the desired duration, in ms
     */
    setToastMessage = (message, severity, duration) => {
        this.setState({
            toastMessage: message,
            toastSeverity: severity ? severity : Toast.Severity.ERROR,
            toastDuration: duration ? duration : Toast.DEFAULT_DURATION
        });
    }

    /**
     * Returns true if the game id is valid
     * @returns {boolean}
     */
    isGameIdValid = () => {
        return this.state.gameId.trim().length === GameProvider.GAME_ID_LENGTH;
    }

    /**
     * Returns true if the train name is valid
     * @returns {boolean}
     */
    isTrainNameValid = () => {
        return this.state.trainName.trim().length >= GameProvider.MIN_TRAIN_NAME_LENGTH &&
            this.state.trainName.trim().length <= GameProvider.MAX_TRAIN_NAME_LENGTH;
    }

    /**
     * Adds a Place to the array of available Places
     * @param props the Place to add
     * @param callback (optional) callback function
     */
    addPlaceInPlay = (props, callback) => {
        const id = props.id;
        const place = _.find(this.state.placesInPlay, { "id": id });

        if(!place) {
            this.setState((prevState) => ({
                placesInPlay: [...prevState.placesInPlay, {...props, status: PlaceStatus.ACTIVE}],
                placeChanged: props.id
            }), () => {
                if(callback) {
                    callback();
                }
            });
        }
    }

    /**
     * Adds the current visible places to be in play
     */
    addAllPlaces = () => {
        this.state.searchResults.forEach(item => {
            const place = _.find(this.state.placesInPlay, { "id": item.id });
            if(!place) {
                this.setState((prevState) => ({
                    placesInPlay: [...prevState.placesInPlay, {...item, status: PlaceStatus.ACTIVE}],
                }));
            }
        });
    }

    /**
     * Removes all places from in play
     */
    removeAllPlaces = () => {
        this.setState({
            placesInPlay: []
        });
    }

    /**
     * Removes a Place from the array of available Places
     * @param props the Place to remove
     * @param callback (optional) callback
     */
    removePlaceInPlay = (props, callback) => {
        const id = props.id;
        const place = _.find(this.state.placesInPlay, { "id": id });

        if(place) {
            this.setState((prevState) => ({
                placesInPlay: _.filter(prevState.placesInPlay, obj => {
                    return obj.id !== props.id
                }),
                placeChanged: props.id
            }), () => {
                if(callback) {
                    callback();
                }
            });
        }
    }

    /**
     * Sets the GameContext places in play (after querying the backend)
     * @param places an array of Restaurant objects
     */
    setPlacesInPlay = (places) => {
        this.setState({
            placesInPlay: places
        });
    }

    /**
     * Updates the Place state context of the current game
     * @param data a valid last_turn_result JSON object
     */
    updatePlaceStates = (data) => {
        this.setState({
            isUpdating: true
        }, () => {
            const id = data.restaurant_id;
            const action = data.action;

            const updatedPlace = _.find(this.state.placesInPlay, {"id": id});
            if (updatedPlace) {
                switch (action) {
                    case PlaceAction.ELIMINATE:
                        updatedPlace.status = PlaceStatus.ELIMINATED;
                        break;
                    case PlaceAction.BRING_BACK:
                        updatedPlace.status = PlaceStatus.ACTIVE;
                        break;
                    default:
                        console.log("No action for: " + action);
                }

                this.setState(prevState => ({
                   placesInPlay: prevState.placesInPlay.map(place => {
                       return place.id === id ? updatedPlace : place;
                   })
                }));
            } else {
                this.setToastMessage("Could not update restaurant state");
            }
        });
    }

    /**
     * Updates the GameContext to note we are creating a game
     * @param isCreating true if we are currently creating the game, false otherwise
     * @returns {Promise<void>}
     */
    setIsCreatingGame = async (isCreating) => {
        return this.setState({
            isCreatingGame: isCreating
        });
    }

    /**
     * Updates the GameContext to note we are connecting to a game
     * @param isConnecting true if we are currently connecting, false otherwise
     * @returns {Promise<void>}
     */
    setIsConnectingToGame = async (isConnecting) => {
        return this.setState({
            isConnectingToGame: isConnecting
        });
    }

    /**
     * @returns {boolean} true if we are trying to connect to the game, false otherwise
     */
    getIsConnectingToGame = () => {
        return this.state.isConnectingToGame;
    }

    /**
     * Updates the GameContext to note we are starting a game
     * @param isStarting true if we are currently starting the game, false otherwise
     * @returns {Promise<void>}
     */
    setIsStartingGame = async (isStarting) => {
        return this.setState({
            isStartingGame: isStarting
        });
    }

    /**
     * Updates the GameContext to note we are boarding the train
     * @param isBoarding true if we are boarding, false otherwise
     * @returns {Promise<void>}
     */
    setIsBoarding = async (isBoarding) => {
        return this.setState({
            isBoarding: isBoarding
        });
   }

    /**
     * Sets the state of the player action occurring
     * @param actionOccurring true if a player action is occurring, false otherwise
     * @returns {Promise<void>}
     */
    setIsPlayerAction = async (actionOccurring) => {
        return this.setState({
            isPlayerAction: actionOccurring
        });
    }

    /**
     * Updates the GameContext with the given passenger
     * @param passengers an array of Passenger objects
     */
    setPassengers = (passengers) => {
        this.setState({
            passengers: passengers
        });
    }

    /**
     * Sets the id of the current player and resets the player action state
     * @param id a valid player id
     */
    setCurrentPlayerId = (id) => {
        this.setState({
            currentPlayerId: id,
            isPlayerAction: false,
            error: ""
        }, () => {
            const event = new Event(Events.PLAYER_CHANGED);
            const ele = document.querySelector("#in-game-name-id");
            if(ele) {
                ele.dispatchEvent(event);
            }
        });
    }

    /**
     * @returns {Array[]} an array of all passengers in the game
     */
    getPassengers = () => {
        return this.state.passengers;
    }

    /**
     * @returns {Array[]} an array of passengers currently online
     */
    getPassengersOnline = () => {
        return _.filter(this.state.passengers, {"online": true});
    }

    /**
     * @returns {Array[]} an array of passengers currently offline
     */
    getPassengersOffline = () => {
        return _.filter(this.state.passengers, {"offline": true});
    }

    /**
     * @returns {string} the name of the current player
     * @param userContext a valid UserContext
     */
    getCurrentPlayerName = (userContext) => {
        const player = _.find(this.state.passengers, { "id": this.state.currentPlayerId });
        let name = player?.name;
        if(this.isCurrentPlayer(userContext)) {
            name = "Your turn!";
        }
        return name;
    }

    /**
     * Returns true if it is the current user's turn, false otherwise
     * @param userContext a valid UserContext
     * @returns {boolean} true if it is the current user's turn
     */
    isCurrentPlayer = (userContext) => {
        return userContext.getUserId() === this.state.currentPlayerId
    }

    /**
     * Decrements the player timer
     */
    timerCountDown = () => {
        this.setState(prevState => ({
            timeLeft: prevState.timeLeft - 1
        }), () => {
            if(this.state.timeLeft <= 0) {
                this.setState({
                    timeLeft: 0
                });
                clearInterval(this.playerTimer);
            }
        });
    }

    /**
     * Sets the player timer
     * @param duration the duration of the timer. Value of -1 means to stop timer
     */
    startPlayerTimer = (duration) => {
        // clear the previous timer
        if(this.playerTimer) {
            clearInterval(this.playerTimer);
        }

        this.setState({
            timeLeft: duration
        }, () => {
            this.playerTimer = setInterval(this.timerCountDown, 1000);
        });
    }

    /**
     * Sets the winning restaurant to the given object
     * @param place a valid restaurant object
     */
    setWinningPlace = (place) => {
        this.setState({
            winningPlace: place
        });
    }

    /**
     * Sets the state of the player id to remove from the current game
     * @param id a valid player id
     */
    setPlayerRemoved = (id) => {
        this.setState({
            removePlayerId: id
        });
    }

    /**
     * Sets the restaurants that are currently visible
     * @param places an array of restaurants
     * @param event the click event (optional)
     */
    setVisiblePlaces = (places, event) => {
        if(event) {
            event.stopPropagation();
        }
        const items = places ? places : [];

        this.setState({
            visiblePlaces: items
        });
    }

    isMeetingTimeValid = () => {
        return this.state.meetingTime >= Date.now();
    }

    setMeetingTime = (date) => {
        this.setState({
            meetingTime: new Date(date)
        });
    }

    getMeetingTime = () => {
        return this.state.meetingTime;
    }

    setDisplayType = (type) => {
        this.setState({
            displayType: type
        });
    }

    /**
     * Resets the game state to some default values
     */
    resetGameState = () => {
        this.setGameState(GameState.NOT_STARTED);
        this.setPassengers([]);
        this.setPlacesInPlay([]);
        this.setVisiblePlaces([]);
        this.setWinningPlace(undefined);
    }

    hasYelpRequests = () => {
        return this.state.canQueryYelp;
    }

    /**
     * @returns {Promise<boolean>} the number of remaining requests we have for today
     */
    updateYelpStatus = async () => {
        let canQueryYelp = true;
        let resp = {};

        try {
            resp = await gameContextService.fetchWithTimeout('/api/restaurants/status', {method: 'GET'});
        } catch(error) {
            console.error("Error getting remaining requests");
        }

        if(resp?.status === 429) {
           canQueryYelp = false;
        }

        this.setState({
            canQueryYelp: canQueryYelp
        });

        return canQueryYelp;
    }

    render() {
        return (
            <GameContext.Provider
                value={{
                    ...this.state,

                    getCurrentPlayerName: this.getCurrentPlayerName,
                    getDisplayPage: this.getDisplayPage,
                    getGameState: this.getGameState,
                    getGameId: this.getGameId,
                    getIsConnectingToGame: this.getIsConnectingToGame,
                    getMeetingTime: this.getMeetingTime,
                    getPassengerName: this.getPassengerName,
                    getPassengers: this.getPassengers,
                    getPassengersOffline: this.getPassengersOffline,
                    getPassengersOnline: this.getPassengersOnline,
                    getPlaces: this.getPlaces,
                    getPlaceName: this.getPlaceName,
                    getPlacesRequest: this.getPlacesRequest,
                    getTrainName: this.getTrainName,
                    hasYelpRequests: this.hasYelpRequests,
                    updateYelpStatus: this.updateYelpStatus,

                    // functions
                    addAllPlaces: this.addAllPlaces,
                    addPlaceInPlay: this.addPlaceInPlay,
                    clearErrors: this.clearErrors,
                    isCurrentPlayer: this.isCurrentPlayer,
                    isGameIdValid: this.isGameIdValid,
                    isMeetingTimeValid: this.isMeetingTimeValid,
                    isTrainNameValid: this.isTrainNameValid,
                    prettyPrintJson: this.prettyPrintJson,
                    removeAllPlaces: this.removeAllPlaces,
                    removePlaceInPlay: this.removePlaceInPlay,
                    resetGameState: this.resetGameState,

                    setCurrentPlayerId: this.setCurrentPlayerId,
                    setDisplayPage: this.setDisplayPage,
                    setDisplayType: this.setDisplayType,
                    setDoSortResults: this.setDoSortResults,
                    setFilterDistance: this.setFilterDistance,
                    setFilterPrice: this.setFilterPrice,
                    setGameId: this.setGameId,
                    setGameIdFromEvent: this.setGameIdFromEvent,
                    setGameOwnerId: this.setGameOwnerId,
                    setGameState: this.setGameState,

                    // action functions
                    setIsBoarding: this.setIsBoarding,
                    setIsCreatingGame: this.setIsCreatingGame,
                    setIsConnectingToGame: this.setIsConnectingToGame,
                    setIsSearching: this.setIsSearching,
                    setIsStartingGame: this.setIsStartingGame,

                    setMeetingTime: this.setMeetingTime,
                    setPassengers: this.setPassengers,
                    setPlacesInPlay: this.setPlacesInPlay,
                    setPlayerRemoved: this.setPlayerRemoved,
                    setSearchResults: this.setSearchResults,
                    setToastMessage: this.setToastMessage,
                    setTrainName: this.setTrainName,
                    setTrainNameFromEvent: this.setTrainNameFromEvent,
                    setVisiblePlaces: this.setVisiblePlaces,
                    setWinningPlace: this.setWinningPlace,

                    startPlayerTimer: this.startPlayerTimer,
                    updatePlaceStates: this.updatePlaceStates,
                }}
            >
                {this.props.children}
            </GameContext.Provider>
        )
    }
}

// used to identify broken games
export const GameId = {
    UNKNOWN: "UNKNOWN"
}

// possible states of a Game
export const GameState = {
    GAME_OVER: "GAME_OVER",
    IN_GAME: "IN_GAME",
    NOT_STARTED: "NOT_STARTED",
    UNKNOWN: "UNKNOWN"
}

export const PlaceAction = {
    BRING_BACK: "BRING_BACK",
    ELIMINATE: "ELIMINATE"
}

export const PlaceStatus = {
    ACTIVE: "ACTIVE",
    ELIMINATED: "ELIMINATED",
    UNKNOWN: "UNKNOWN"
}

export const DISPLAY_TYPE = {
    DESKTOP: "DESKTOP",
    MOBILE: "MOBILE"
}

export default GameProvider;