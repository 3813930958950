import React, {Component} from "react";
import common_styles from "../../components/Common/common.module.scss";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import styles from "./About.module.scss";
import * as tutorial from "../../tutorial";
import HomePage from "../HomePage/HomePage";
import {DISPLAY_TYPE} from "../../context/GameProvider";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import GameContext from "../../context/GameContext";
import FieldIcon from "../../components/Common/FieldIcon/FieldIcon";
import FAQ from "./FAQ";

class Tutorial extends Component {
    static contextType = GameContext;

    render() {
        const clazz = this.context.displayType === DISPLAY_TYPE.MOBILE ? styles.mobile : styles.desktop;

        return (
            <div className={[common_styles.map_bg, common_styles.about].join(" ")}>
                <div className={styles.background} />
                <Swiper navigation={true}
                        modules={[Navigation]}
                        className={[styles.swiper, clazz].join(" ")}>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 01: Become a Conductor
                            </h3>
                            <ol>
                                <li>One player in the game must become a Conductor. Every other player will be a Passenger</li>
                                <li>Have your designated Conductor select the <b>Conductor</b> button</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Main Page image"} src={tutorial["step_main_page"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 02: Search for Restaurants (Conductor)
                            </h3>
                            <ol>
                                <li>Enter the restaurant name or cuisine type if desired</li>
                                <li>Enter the location to search within</li>
                                <li>Click on the <b>Search</b> button (search icon)</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Conductor Search"} src={tutorial["step_search"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 03: Add Restaurants to the Game (Conductor)
                            </h3>
                            <ol>
                                <li>Add the desired restaurants to the game</li>
                                <li>When you're happy with the selections, click on the <b>NEXT</b> button</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Conductor Selection"} src={tutorial["step_select"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 04: Set the Train Name and Destination (Conductor)
                            </h3>
                            <ol>
                                <li>Enter your name so others know who you are</li>
                                <li>Enter a cool name for your train</li>
                                <li>Set the meetup time</li>
                                <li>Click on the <b>CREATE GAME</b> button to create your Lunch Train!</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Conductor Settings"} src={tutorial["step_settings"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 05: Invite Your Friends! (Conductor)
                            </h3>
                            <ol>
                                <li>Click on the <b>INVITE</b> button to invite your friends to the game</li>
                                <li>All they need to do is click on the game link, enter their name, and board the train!</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Passenger List"} src={tutorial["step_passengers"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 06: Board the Train (Passenger)
                            </h3>
                            <ol>
                                <li>Click on the invitation link to board the train</li>
                                <li>Enter your name so others know who you are</li>
                                <li>Alternatively, Passengers can manually enter the Game ID of the desired train</li>
                                <li>Click on the <b>BOARD TRAIN</b> button to board the train!</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Name Entry"} src={tutorial["step_name_entry"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 07: Start the Game (Conductor)
                            </h3>
                            <ol>
                                <li>Once all passengers have boarded the train, it's time to depart</li>
                                <li>Click on the <b>DEPART!</b> button to launch the train!</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Depart!"} src={tutorial["step_passengers_ready"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 08: Play the Game!
                            </h3>
                            <ol>
                                <li>When it's your turn, it's time to decide whether you want to remove a restaurant or bring one back.
                                    Be careful. There is a time limit!</li>
                                <li>Click on the restaurant action button to perform your selection</li>
                                <li>Passengers each have one "bring back", so use it wisely!</li>
                                <li>When there is only 1 restaurant remaining, the game is over</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"In Game"} src={tutorial["step_in_game"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={styles.tutorial}>
                            <h3>
                                Step 09: Meet at your Destination
                            </h3>
                            <ol>
                                <li>When the game is over, the winning destination will be displayed</li>
                                <li>Click on the restaurant name to learn more about the restaurant</li>
                                <li>Click on the directions icon to get directions to your destination</li>
                            </ol>
                            <div className={styles.image}>
                                <img alt={"Game Over"} src={tutorial["step_finit"]} />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className={[styles.actions, styles.tutorial_actions].join(" ")}>
                    <div className={styles.action}
                         onClick={() => this.context.setDisplayPage(<HomePage />)}>
                        <FieldIcon icon={"rotate-left"}
                                   className={styles.action_button}
                                   size={"extra_large"}
                                   title="Go back" />
                    </div>
                </div>
            </div>
        )
    }
}

Tutorial.defaultProps = {
    displayName: "Tutorial"
}

export default Tutorial;