import React, {Component} from "react";
import styles from "./PassengerRow.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserContext from "../../../context/UserContext";
import User from "../../../User";
import PropTypes from "prop-types";
import gameContextService from "../../../service/GameContextService";
import Ticket from "../../Common/Ticket/Ticket";
import GameContext from "../../../context/GameContext";

class PassengerRow extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.ticketRef = undefined;

        this.state = {
            isMounted: false
        }
    }

    componentDidMount() {
        gameContextService.animateHeader(this.ticketRef);

        this.setState({
            isMounted: true
        });
    }

    render() {
        return (
            <UserContext.Consumer>
                {userContext => (
                    <div className={styles.passenger_row}
                         ref={(e) => this.ticketRef = e}>
                        <Ticket name={this.props.name}
                                gameId={this.context.gameId}
                                meetingTime={this.context.meetingTime.toString()}
                                online={this.props.online}
                                role={this.props.role}
                                trainName={this.context.trainName} />
                        {userContext.role === User.CONDUCTOR && this.props.id !== userContext.userId &&
                            <div className={styles.action_button}>
                                <FontAwesomeIcon icon="circle-minus"
                                                 className={styles.action_button}
                                                 onClick={() => gameContextService.removePassenger(this.props.id)}/>
                            </div>
                        }
                    </div>
                )}
            </UserContext.Consumer>
        )
    }
}

PassengerRow.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    online: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired
}

PassengerRow.defaultProps = {
    id: "0000-0000",
    name: "Unknown",
    online: false,
    role: User.PASSENGER.toString()
}

export default PassengerRow;