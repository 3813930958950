import React, {Component} from "react";
import styles from "./About.module.scss";
import common_styles from "../../components/Common/common.module.scss";
import GameContext from "../../context/GameContext";
import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tutorial from "./Tutorial";
import FieldIcon from "../../components/Common/FieldIcon/FieldIcon";
import About from "./About";
import googleBadge from "../../assets/google-play-badge-large.png";
import iphoneBadge from "../../assets/iphone-badge-large.png";

class FAQ extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.state = {
            expanded: undefined
        }
    }

    handleChange = (panel) => {
        this.setState((prevState) => ({
            expanded: prevState.expanded === panel ? "" : panel
        }));
    }

    render() {
        return (
            <div className={common_styles.map_bg}>
                <div className={[common_styles.main_content, styles.content].join(" ")}>
                    <Accordion expanded={this.state.expanded === 'panel_what'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_what')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <h2 className={common_styles.hidden}>What is the Lunch Train?</h2>
                            <Typography sx={{ color: 'text.secondary' }}>What is The Lunch Train?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                The Lunch Train is an interactive game which can be used by a group of people
                                to determine where to eat or meet.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_for'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_for')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>Can this be used for more than just lunch?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Of course! You can search for restaurants that serve breakfast, brunch, lunch, or dinner or
                                you can search for bars and lounges.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_why'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_why')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>Why did you make such a cool game?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                We found that organizing a group lunch at the office was no fun at all. To resolve this,
                                we gathered a group of colleagues together and started writing down restaurants on
                                the whiteboard and allowed each person to remove a restaurant or bring one back. As it
                                turns out, this was quite fun so we decided to digitize it!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_app'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_app')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>Is there a native app I can download?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Click on one of the links below to download the application on your phone.
                            </Typography>
                            <div className={common_styles.app_store_icons}>
                                <a href='https://play.google.com/store/apps/details?id=com.thelunchtrain.tlt&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                   target="_blank">
                                    <img className={common_styles.store_icon}
                                         alt='Get it on Google Play'
                                         src={googleBadge} />
                                </a>
                                <a href='https://apps.apple.com/app/the-lunch-train/id1629515045'
                                   target="_blank">
                                    <img className={common_styles.store_icon}
                                         alt='Download on the App Store'
                                         src={iphoneBadge} />
                                </a>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_favorites'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_favorites')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>Can I save a list of my favorite restaurants?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Working on it!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_features'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_features')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>Are you going to add more cool features?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                That's the plan! We're talking animated emojis, audio, and more fun gameplay (credit card roulette)!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_how'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_how')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>How does it work?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Check out the <span
                                                className={styles.link}
                                                onClick={() => this.context.setDisplayPage(<Tutorial />)}><b>How To</b> </span>
                                page to learn how to play!
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_multiple'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_multiple')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>I see multiple tickets for the same passenger</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                This may occur if cookies are disabled. Please enable cookies and try again.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_offline'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_offline')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>I'm appearing OFFLINE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Network connections can get wonky when put the application in the background.
                                Try minimizing the app/web page and then returning. If this does not work,
                                trying switching back and forth between browser tabs.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={this.state.expanded === 'panel_last'}
                               className={styles.accordion}
                               onChange={() => this.handleChange('panel_last')}>
                        <AccordionSummary
                            className={styles.question}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={{ color: 'text.secondary' }}>How can I provide feedback to/contact The Lunch Train?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Feedback and comments can be sent to: <a href="mailto:support@thelunchtrain.com?subject=TLT Feedback">support(at)thelunchtrain.com</a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className={styles.actions}>
                    <div className={styles.action}
                         onClick={() => this.context.setDisplayPage(<About />)}>
                        <FieldIcon icon={"rotate-left"}
                                   className={styles.action_button}
                                   size={"extra_large"}
                                   title="Go back" />
                    </div>
                </div>
            </div>
        );
    }
}

FAQ.defaultProps = {
    displayName: "FAQ"
}

export default FAQ;