import React, {Component} from "react";
import {CircularProgress, Menu, MenuItem, Skeleton} from "@mui/material";
import common_styles from "../Common/common.module.scss";
import styles from "./SearchHeader.module.scss";
import TextFieldWithIcon from "../Common/TextFieldWithIcon/TextFieldWithIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GameContext from "../../context/GameContext";
import gameContextService from "../../service/GameContextService";
import FieldIcon from "../Common/FieldIcon/FieldIcon";
import "../Common/theme_variables.scss";

class SearchHeader extends Component {
    static contextType = GameContext;

    static CURRENT_LOCATION = "Current location";

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            searchTerm: "",
            searchLocation: "",
            searchLat: "",
            searchLong: "",
            displayMenu: false,
            gettingLocation: false,
        }
    }

    /**
     * Updates the location available value on mount
     */
    componentDidMount() {
        const locationAvailable = "geolocation" in navigator;

        this.setState({
            isMounted: true,
            locationAvailable: locationAvailable
        });
    }

    /**
     * Sets the given search term (restaurant name)
     * @param event the DOM text field
     */
    setSearchTerm = (event) => {
        this.setState({
            searchTerm: event.target.value
        });
    }

    /**
     * Sets the given search location
     * @param event the DOM text field
     */
    setSearchLocation = (event) => {
        this.setState({
            searchLocation: event.target.value,
            searchLat: "",
            searchLong: ""
        })
    }

    setCurrentLocation = () => {
        if(this.state.locationAvailable) {
            let lat = "";
            let long = "";
            const self = this;
            this.setState({
                gettingLocation: true,
                searchLocation: "Getting current location..."
            });
            navigator.geolocation.getCurrentPosition(
                function(position) {
                    lat = position?.coords?.latitude;
                    long = position?.coords?.longitude;
                    self.setState({
                        searchLocation: SearchHeader.CURRENT_LOCATION,
                        searchLat: lat,
                        searchLong: long,
                        gettingLocation: false
                    }, () => {
                        self.handleSearch();
                    });
                },
                function(error) {
                    self.setState({
                        gettingLocation: false,
                        searchLocation: ""
                    });
                    self.context.setToastMessage(error.message);
                }
            );
        } else {
            this.context.setToastMessage("Location not found. Please enter a value.");
        }

        this.handleMenuClose();
    }

    /**
     * Handles searching for places if the "Enter" key is pressed
     * @param event the input field we are monitoring for an "Enter" key
     * @returns {Promise<void>}
     */
    async handleKeyEvent(event) {
        if(event.key === "Enter") {
            await this.handleSearch();
        }
    }

    canSearch = () => {
        const ZIP_CODE_LENGTH = 5;
        return this.state.searchLocation === SearchHeader.CURRENT_LOCATION ||
            this.state.searchLocation.trim().length >= ZIP_CODE_LENGTH &&
            !this.state.searchLocation.toLowerCase().includes("current location");
    }

    /**
     * Handles searching for places given the current search term and location
     * @returns {Promise<void>}
     */
    handleSearch = async () => {
        const params = {};
        this.context.setToastMessage("");

        // send the search event
        document.dispatchEvent(new Event(Event.SEARCH_CLICKED));

        // search using user entered value
        if(this.state.searchLocation !== SearchHeader.CURRENT_LOCATION) {
            if (this.state.searchLocation.trim()) {
                params.location = this.state.searchLocation.trim();
            } else {
                const message = "You must specify a 'Location' value";
                this.context.setToastMessage(message);
                return;
            }
        } else {
            // search using lat/long
            params.lat = this.state.searchLat;
            params.long = this.state.searchLong;
        }

        if (this.state.searchTerm.trim()) {
            params.term = this.state.searchTerm.trim();
        }

        await gameContextService.searchForPlaces(params);
    }

    /**
     * Handles the clicking of the dropdown arrow within the Location text field
     * @param event the DOM element clicked on
     */
    handleDropdownClick = (event) => {
        this.setState({
            displayMenu: true,
            anchorEl: event.currentTarget
        });
    }

    /**
     * Closes the "Current location" menu
     */
    handleMenuClose = () => {
        this.setState({
            displayMenu: false,
            anchorEl: null
        });
    }

    render() {
        return (
            this.state.isMounted ?
                <div className={styles.container}>
                    <div className={styles.header_row}>
                        {/* place name/cuisine search */}
                        <TextFieldWithIcon icon="utensils"
                                           className={"step-cuisine"}
                                           placeholder="Name/cuisine (optional)"
                                           title="Restaurant restaurant name or type"
                                           value={this.state.searchTerm}
                                           onChange={(e) => this.setSearchTerm(e)}
                                           handleKeyDown={(e) => this.handleKeyEvent(e)} />
                        <FieldIcon icon={"circle-question"}
                                   title="Help"
                                   className={common_styles.help_button}
                                   onClick={this.props.openTour} />

                    </div>
                    <div className={styles.header_row}>
                        {/* place location search */}
                        <TextFieldWithIcon icon="location-dot"
                                           placeholder="Location"
                                           className={[styles.location, "step-location"].join(" ")}
                                           title="Location (address, zip code, etc). Click to use current location"
                                           value={this.state.searchLocation}
                                           autoFocus
                                           onButtonClick={() => this.setCurrentLocation()}
                                           onChange={(e) => this.setSearchLocation(e)}
                                           handleKeyDown={(e) => this.handleKeyEvent(e)}>

                            {/* little clickable location dot or progress spinner */}
                            {!this.state.gettingLocation ?
                                <FontAwesomeIcon
                                    icon="caret-down"
                                    className={styles.location_dropdown}
                                    aria-controls={this.state.displayMenu ? 'demo-customized-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={this.state.displayMenu ? 'true' : undefined}
                                    onClick={this.handleDropdownClick} />
                                :
                                <CircularProgress className={styles.location_progress} size={12}/>
                            }

                            <Menu
                                id="current-location-menu"
                                className={styles.location_menu}
                                elevation={0}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                anchorEl={this.state.anchorEl}
                                open={this.state.displayMenu}
                                onClose={this.handleMenuClose}>
                                <MenuItem
                                    onClick={this.setCurrentLocation}
                                    disableRipple>
                                    {SearchHeader.CURRENT_LOCATION}
                                    <FontAwesomeIcon
                                        className={styles.location_icon}
                                        icon="location-arrow" />
                                </MenuItem>
                            </Menu>

                        </TextFieldWithIcon>

                        {/* search button */}
                        <FieldIcon
                            clickable={true}
                            disabled={!this.canSearch()}
                            icon="magnifying-glass"
                            title="Apply search"
                            className={[styles.search_button, "step-search"].join(" ")}
                            onClick={() =>
                                this.handleSearch()} />
                    </div>
                </div>
                :
                <Skeleton variant="rectangular" height="100%" />
        );
    }
}

export default SearchHeader;