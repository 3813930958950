import React, {Component} from "react";
import GameContext from "../../../context/GameContext";
import styles from "./GameWinner.module.scss";
import * as star_images from "../../../star_icons";
import {ANIMATE} from "../../../Animate";
import DirectionsIcon from '@mui/icons-material/Directions';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import gameContextService from "../../../service/GameContextService";
import HomePage from "../../../pages/HomePage/HomePage";
import FieldIcon from "../../Common/FieldIcon/FieldIcon";
import winner from "../../../assets/place_types/winner.png";

/**
 * Game Winning Component to be display at the end of each game
 */
class GameWinner extends Component {
    static contextType = GameContext;

    /**
     * Opens Google maps in a new tab
     * @param place a valid restaurant
     */
    openMap = (place) => {
        if(place.name && place.location?.display_address?.toString()) {
            // window.open(url, "_blank");
            let url = "https://www.google.com/maps/search/";
            const urlName = place.name.replace(" ", "+");
            const location = place.location?.display_address?.toString().replace(" ", "+");
            url += [urlName, location].join("+");

            gameContextService.openUrl(url);
        }
    }

    render() {
        const className = [styles.game_winner, ANIMATE.ANIMATED, ANIMATE.TADA].join(" ");
        const categoryString = this.props.category ? this.props.category.join(", ") : "";
        const firstCategory = this.props.category ? this.props.category[0] : "generic";
        const categoryIcon = gameContextService.getPlaceIcon(this.props.category);

        return (
            <div className={className}>
                <div className={styles.winner_img}>
                    <img src={winner} />
                </div>
                <div className={styles.winner_text}>NEXT STOP:</div>
                <div className={styles.game_row}>
                    {/* icon */}
                    <img className={styles.place_icon}
                         title={firstCategory}
                         src={categoryIcon}
                         alt={firstCategory}
                         onClick={() => gameContextService.openUrl(this.props.url)} />

                    {/* place row */}
                    <div className={styles.place_overview}>
                        {/* name */}
                        <div className={styles.place_name}
                             title={this.props.name}
                             id={this.props.id}
                             onClick={() => gameContextService.openUrl(this.props.url)}>
                            {this.props.name}

                            {/* url */}
                            {this.props.url &&
                                <div className={styles.place_url} title="Click to view website">
                                    <FontAwesomeIcon icon={"arrow-up-right-from-square"}/>
                                </div>}
                        </div>

                        {/* rating */}
                        <img className={styles.place_rating}
                             title={"Rating: " + this.props.rating}
                             src={star_images[("star_" + this.props.rating).replace(".", "_")]}
                             alt={"Rating: " + this.props.rating}/>

                        {/* category */}
                        <div className={styles.place_category} title={categoryString}>{categoryString}</div>

                        {/* address */}
                        <div className={styles.place_location}
                             title={this.props.location.display_address.join("\n")}>{this.props.location.display_address[0]}</div>
                    </div>
                    <div className={styles.divider}/>
                    <DirectionsIcon titleAccess="Click to view on a map"
                                    className={styles.action_button}
                                    onClick={() => this.openMap(this.props)}/>
                </div>
                <div className={styles.meeting_time}>
                    Arriving at: {gameContextService.getMeetingTime()}
                </div>
                <div className={styles.actions}>
                    <div className={styles.action}
                         onClick={() => this.context.setDisplayPage(<HomePage />)}>
                        <FieldIcon icon={"home-lg-alt"}
                                   className={styles.action_button}
                                   onClick={() => this.context.setDisplayPage(<HomePage />)}
                                   size={"extra_large"}
                                   title="Return to homepage" />
                    </div>
                </div>
            </div>
        )
    }
}

GameWinner.propTypes = {
    category: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rating: PropTypes.number,
    location: PropTypes.object,
    distance: PropTypes.number,
    price: PropTypes.string,
    url: PropTypes.string
}

GameWinner.defaultProps = {
    category: ["food_american"],
    id: "xxx",
    name: "The Liberty Tavern",
    rating: "4.5",
    location: {
        address1: "",
        address2: "",
        address3: "",
        city: "",
        country: "",
        display_address: [
            "",
            ""
        ],
        state: "",
        zip_code: ""
    },
    distance: 0,
    price: "$$",
    url: ""
}

export default GameWinner;