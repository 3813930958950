import React, {Component} from "react";
import styles from "./Avatars.module.scss";
import GameContext from "../../../context/GameContext";
import _ from "lodash";
import * as avatar_images from "../../../avatar_icons";

class Avatars extends Component {
    static contextType = GameContext;

    render() {
        const players = this.context.getPassengers();

        return (
            <div className={styles.avatars}>
                {_.sortBy(players, ["name"])
                    .map((obj, index) => (
                            <div className={styles.avatar} key={obj.id}>
                                {this.context.currentPlayerId === obj.id && <div className={styles.highlight} />}
                                <img src={avatar_images["avatar_" + (obj.avatar ? obj.avatar : "blue")]}
                                     alt="avatar"
                                     className={obj.online ? "" :styles.offline} />
                                <div className={styles.name_block}>
                                    <span className={[styles.name, obj.online ? "" : styles.offline].join(" ")}>{obj.name.substring(0, 2)}</span>
                                </div>
                            </div>
                    ))
                }
            </div>
        )
    }

}

export default Avatars;