import UserContext from "./UserContext";
import {Component} from "react";
import User from "../User";

class UserProvider extends Component {
    static MIN_NAME_LENGTH = 2;
    static MAX_NAME_LENGTH = 10;

    state = {
        name: "",
        role: User.PASSENGER,
        userId: "0000-0000",
        bringBacksAvailable: -1
    }

    /**
     * Sets the username given a text field
     * @param event the input field containing the name
     */
    setNameFromEvent = (event) => {
        if(event.target.value.trim().length <= UserProvider.MAX_NAME_LENGTH) {
            this.setState({
                name: event.target.value
            });
        }
    }

    /**
     * Sets the user id which is generated from the backend API
     * @param id a uuid
     */
    setUserId = (id) => {
        this.setState({
            userId: id
        });
    }

    /**
     * @returns {string} the current user id
     */
    getUserId = () => {
        return this.state.userId;
    }

    /**
     * Sets the username given a text field
     * @param name the name to set
     */
    setName = (name) => {
        return this.setState({
            name: name
        });
    }

    /**
     * Sets the user role to the given value
     * @param role a valid User role
     */
    setRole = (role) => {
        this.setState({
            role: role
        });
    }

    /**
     * Sets the number of bring backs available for the current user
     * @param bringBacksAvailable a number >= 0
     */
    setBringBacks = (bringBacksAvailable) => {
        this.setState({
            bringBacksAvailable: bringBacksAvailable
        });
    }

    /**
     * Decrements the user's bring backs by 1
     */
    removeBringBack = () => {
        this.setState(prevState => ({
            bringBacksAvailable: prevState.bringBacksAvailable - 1
        }));
    }

    /**
     * Returns true if the username is valid
     * @returns {boolean}
     */
    isNameValid = () => {
        return this.state.name.trim().length >= UserProvider.MIN_NAME_LENGTH && this.state.name.trim().length <= UserProvider.MAX_NAME_LENGTH;
    }

    render() {
        return (
            <UserContext.Provider
                value={{
                    ...this.state,

                    // functions
                    getUserId: this.getUserId,
                    isNameValid: this.isNameValid,
                    removeBringBack: this.removeBringBack,

                    setBringBacks: this.setBringBacks,
                    setName: this.setName,
                    setNameFromEvent: this.setNameFromEvent,
                    setRole: this.setRole,
                    setUserId: this.setUserId
                }}
                >
                {this.props.children}
            </UserContext.Provider>
        )
    }
}

export default UserProvider;