import React, {Component} from "react";
import {Skeleton} from "@mui/material";
import common_styles from "../../components/Common/common.module.scss";
import TextFieldWithIcon from "../../components/Common/TextFieldWithIcon/TextFieldWithIcon";
import UserContext from "../../context/UserContext";
import GameContext from "../../context/GameContext";
import User from "../../User";
import GameProvider, {GameId} from "../../context/GameProvider";
import Config from "../Admin/Config";
import FullElementSpinner from "../../components/Common/Spinner/FullElementSpinner";
import gameContextService from "../../service/GameContextService";
import PropTypes from "prop-types";
import Toast from "../../components/Common/Toast/Toast";
import UserProvider from "../../context/UserProvider";
import JoyRide, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TOUR_GUIDE_OPTIONS} from "../../components/Common/tour_guide";
import FieldIcon from "../../components/Common/FieldIcon/FieldIcon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTime from "../../components/Common/DateTime/DateTime";
import HomePage from "../HomePage/HomePage";

class NameEntry extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            gameId: this.props.gameId?.toUpperCase(),
            isTourOpen: false,
            stepIndex: 0
        }
    }

    async componentDidMount() {
        this.setState({
            isMounted: true
        });

        // set the game ID if we provide it
        if(this.state.gameId) {
            await this.context.setGameId(this.state.gameId)
        } else {
            // game hasn't started or we've created the game and already set the time
            this.context.setMeetingTime(this.roundToNearest(15));
        }
    }

    /**
     * Rounds the current time to the nearest given interval
     * @param nearest an Integer interval (0, 15, 30, 45)
     * @returns {Date} the rounded date (using ceiling)
     */
    roundToNearest = (nearest) => {
        const date = new Date();
        const ms = nearest * 1000 * 60;

        return new Date(Math.ceil(date.getTime() / ms) * ms);
    }

    /**
     * Determine whether the Conductor can proceed to the next step
     * @returns {boolean} true if we can continue, false otherwise
     */
    canContinue = (userContext, gameContext) => {
        let retVal = false;
        userContext.role === User.CONDUCTOR ?
            retVal = userContext.isNameValid() && gameContext.isTrainNameValid() && gameContext.isMeetingTimeValid()
            :
            retVal = userContext.isNameValid() && gameContext.isGameIdValid()
        return retVal;
    }

    /**
     * Removes whitespace from the username and train name
     * @param userContext the UserContext
     * @param gameContext the GameContext
     */
    normalizeInput = async (userContext, gameContext) => {
        await gameContext.setTrainName(gameContext.trainName.trim());
    }

    openTour = () => {
        this.setState({
            isTourOpen: true
        });
    }

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.setState({
                stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
            });
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.setState({
                isTourOpen: false,
                stepIndex: 0
            });
        }
    };

    render() {
        return (
            <GameContext.Consumer>
                {gameContext => {
                    return (
                        <UserContext.Consumer>
                            {userContext => {
                                const canContinue = this.canContinue(userContext, gameContext);
                                const previous = userContext.role === User.CONDUCTOR ? <Config /> : <HomePage />;
                                const tourSteps = userContext.role === User.CONDUCTOR ? this.TOUR_STEPS_CONDUCTOR : this.TOUR_STEPS_PASSENGER;
                                let gameId = gameContext.gameId === GameId.UNKNOWN ? "" : gameContext.gameId;

                                return (
                                    <div className={common_styles.game_container}>
                                        <JoyRide
                                            steps={tourSteps}
                                            stepIndex={this.state.stepIndex}
                                            callback={this.handleJoyrideCallback}
                                            continuous
                                            showSkipButton
                                            run={this.state.isTourOpen}
                                            locale={{last: "Close"}}
                                            styles={{options: TOUR_GUIDE_OPTIONS}}
                                        />
                                        <div className={common_styles.block_header}>
                                            <div>NAME ENTRY</div>
                                        </div>

                                        {gameContext.toastMessage &&
                                            <Toast message={gameContext.toastMessage}
                                                   severity={gameContext.toastSeverity} />}
                                        {gameContext.isCreatingGame &&
                                            <FullElementSpinner message={"Creating game"} />}
                                        {gameContext.isConnectingToGame &&
                                            <FullElementSpinner message={"Connecting to game"} />}

                                        {gameContext.isBoarding ?
                                            <div className={common_styles.username_container}>
                                                <FullElementSpinner message={"Boarding train..."}/>
                                            </div>
                                            :
                                            <div className={common_styles.username_container}>
                                                <FieldIcon icon={"circle-question"}
                                                           title="Help"
                                                           className={common_styles.help_button}
                                                           onClick={this.openTour}/>

                                                <div className={common_styles.textfield_block}>
                                                    {/* username */}
                                                    <TextFieldWithIcon icon="user"
                                                                       placeholder="Your name"
                                                                       title="Username"
                                                                       className="step-username"
                                                                       autoFocus
                                                                       value={userContext.name}
                                                                       isValid={userContext.isNameValid}
                                                                       helperText={`Username must be ${UserProvider.MIN_NAME_LENGTH} to ${UserProvider.MAX_NAME_LENGTH} characters`}
                                                                       onChange={userContext.setNameFromEvent}/>

                                                    {userContext.role === User.CONDUCTOR ?
                                                        /* train name */
                                                        <TextFieldWithIcon icon="train-subway"
                                                                           placeholder="Train name"
                                                                           title="Train name"
                                                                           className="step-train-name"
                                                                           value={gameContext.trainName}
                                                                           isValid={gameContext.isTrainNameValid}
                                                                           helperText={`Train name must be ${GameProvider.MIN_TRAIN_NAME_LENGTH} to ${GameProvider.MAX_TRAIN_NAME_LENGTH} characters`}
                                                                           onChange={gameContext.setTrainNameFromEvent}/>
                                                        :
                                                        /* game id */
                                                        <TextFieldWithIcon icon="passport"
                                                                           placeholder="Game ID"
                                                                           title="Game ID"
                                                                           className="step-game-id"
                                                                           value={gameId}
                                                                           isValid={gameContext.isGameIdValid}
                                                                           helperText={`Game ID must be ${GameProvider.GAME_ID_LENGTH} characters`}
                                                                           onChange={gameContext.setGameIdFromEvent}/>
                                                    }
                                                    {userContext.role === User.CONDUCTOR &&
                                                        /* meeting time */
                                                        <div className={["textfield_container", "step-meet-time"].join(" ")}>
                                                            <FieldIcon icon="calendar-days"
                                                                       title="Meet time"
                                                                       className="step-meet-time" />
                                                            <DatePicker
                                                                selected={gameContext.meetingTime}
                                                                onChange={(date) => gameContext.setMeetingTime(date)}
                                                                className={"test"}
                                                                showTimeSelect
                                                                timeFormat="hh:mm aa"
                                                                timeIntervals={15}
                                                                timeCaption="time"
                                                                dateFormat="MMMM d, yyyy h:mm aa"
                                                                customInput={<DateTime value={gameContext.meetingTime}
                                                                                       error={!gameContext.isMeetingTimeValid()}/>}
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                        <div className={[common_styles.action_button_container, common_styles.dark].join(" ")}>
                                            {/* BACK */}
                                            <div>
                                                <FieldIcon icon={"rotate-left"}
                                                           clickable={true}
                                                           onClick={() => {
                                                               this.normalizeInput(userContext, gameContext);
                                                               gameContext.setDisplayPage(previous);
                                                           }}
                                                           size={"large"}
                                                           title="Go back" />
                                            </div>

                                            {/* CONTINUE */}
                                            <div className={"step-next-button"}>
                                                <FieldIcon icon={"angle-right"}
                                                           clickable={true}
                                                           disabled={(!this.state.isTourOpen || this.state.stepIndex !== tourSteps.length - 1)
                                                               && (!canContinue || gameContext.isBoarding)}
                                                           onClick={async () => {
                                                               this.normalizeInput(userContext, gameContext);
                                                               gameContext.clearErrors();
                                                               if(userContext.role === User.CONDUCTOR) {
                                                                   await gameContextService.createGame();
                                                               }
                                                               await gameContext.setPlayerRemoved(undefined);
                                                               await gameContextService.boardTrain(userContext);
                                                           }}
                                                           size={"large"}
                                                           title="Continue" />
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            }
                        </UserContext.Consumer>
                    )
                }
                }
            </GameContext.Consumer>
        );
    }

    TOUR_STEPS_CONDUCTOR = [
        {
            target: ".step-username",
            content: "What is your name? (2-10 characters)"
        },
        {
            target: ".step-train-name",
            content: "What is your train called? (3-16 characters)",
        },
        {
            target: ".step-meet-time",
            content: "When do you want to meet?"
        },
        {
            target: ".step-next-button",
            content: "Click on the CONTINUE button to start the boarding process!"
        }
    ]

    TOUR_STEPS_PASSENGER = [
        {
            target: ".step-username",
            content: "What is your name? (2-10 characters)"
        },
        {
            target: ".step-game-id",
            content: "What is the game ID? (provided by the conductor)",
        },
        {
            target: ".step-next-button",
            content: "Click on the CONTINUE button to join the game!"
        }
    ]
}

NameEntry.propTypes = {
    gameId: PropTypes.string, // optional
}

NameEntry.defaultProps = {
    displayName: "NameEntry",
    gameId: "",
}

export default NameEntry;