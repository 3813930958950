import React, {Component} from "react";
import styles from "./About.module.scss";

import conductor from "../../assets/users/conductor_icon.png";
import passenger from "../../assets/users/passenger_icon.png";
import train from "../../assets/logo192.png";
import common_styles from "../../components/Common/common.module.scss";
import GameContext from "../../context/GameContext";
import HomePage from "../HomePage/HomePage";
import Tutorial from "./Tutorial";
import FieldIcon from "../../components/Common/FieldIcon/FieldIcon";
import FAQ from "./FAQ";
import about from "../../assets/users/about_icon.png";
import tutorial from "../../assets/tutorial_icon.png"

class About extends Component {
    static contextType = GameContext;

    render() {
        return (
            <div className={[common_styles.map_bg, common_styles.about].join(" ")}>
                <div className={[common_styles.main_content, styles.content].join(" ")}>
                    <div className={styles.main_description}>
                        <div className={styles.description}>
                            Welcome to <b>The Lunch Train</b>! Having trouble figuring out where to eat?
                            We used to as well. That's why we created The Lunch Train.
                        </div>
                        <div className={styles.description}>
                            Now you can have fun when deciding where and when to meet. Just hop aboard The Lunch Train
                            and set the destination for: <b>breakfast, brunch, lunch, dinner, or nightlife!</b>
                        </div>
                    </div>
                    <div className={styles.description}><b>How it works:</b></div>

                    {/* Conductor */}
                    <div className={styles.user_description}>
                        <img alt="Conductor" src={conductor} className={styles.avatar} />
                        <div className={styles.content}>
                            <div><b>Conductor</b></div>
                            A Conductor creates a list of possible restaurants to stop at.
                            The conductor will then invite passengers.
                        </div>
                    </div>

                    {/* Passenger */}
                    <div className={styles.user_description}>
                        <img alt="Passenger" src={passenger} className={styles.avatar} />
                        <div className={styles.content}>
                            <div><b>Passenger</b></div>
                            Each Passenger boards the train.
                        </div>
                    </div>

                    {/* Gameplay */}
                    <div className={styles.user_description}>
                        <img alt="Gameplay" src={train} className={styles.avatar} />
                        <div className={styles.content}>
                            <div><b>Gameplay</b></div>
                            The conductors starts the train and then each passenger gets to make a choice:
                            <b>remove</b> a restaurant or <b>bring one back</b>.
                            When there is one restaurant remaining, all passengers meet at the destination!
                        </div>
                    </div>

                    {/* FAQ */}
                    <div className={styles.faq}
                         onClick={() => {
                             this.context.setDisplayPage(<FAQ />);
                         }}>
                        <img alt="FAQ" src={about} className={styles.avatar} />
                        <div className={styles.content}>
                            <div><b>FAQ</b></div>
                            Click here to access our Frequently Asked Questions!
                        </div>
                    </div>

                    {/* Tutorial */}
                    <div className={styles.faq}
                         onClick={() => {
                             this.context.setDisplayPage(<Tutorial />);
                         }}>
                        <img alt="Tutorial" src={tutorial} className={styles.avatar} />
                        <div className={styles.content}>
                            <div><b>How to Play</b></div>
                            Click here to see how to set up your first train!
                        </div>
                    </div>
                </div>

                {/* Buttons */}
                <div className={styles.actions}>
                    <div className={styles.action}
                         onClick={() => this.context.setDisplayPage(<HomePage />)}>
                        <FieldIcon icon={"rotate-left"}
                                   className={styles.action_button}
                                   size={"extra_large"}
                                   title="Go back" />
                    </div>

                    <div className={styles.action_tutorial}
                         title="Tutorial"
                         onClick={() => this.context.setDisplayPage(<Tutorial />)}>
                    </div>
                </div>
            </div>
        );
    }
}

About.defaultProps = {
    displayName: "About"
}

export default About;