import {Component} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import GameContext from "../../../context/GameContext";

class FilterDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    handleDialogClose = () => {
        this.setState({
            open: false
        });
    };

    render() {
        return (
            <GameContext.Consumer>
                {gameContext => (
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleDialogClose}
                        aria-labelledby="form-dialog-title"
                        fullWidth
                        maxWidth={"md"}
                    >
                        <DialogTitle id="form-dialog-title">Filters</DialogTitle>
                        <DialogContent>
                            <div>Price (up to)</div>
                            <Select
                                labelId="price-label"
                                id="price-id"
                                value={gameContext.filterPrice}
                                label="Price"
                                onChange={gameContext.setFilterPrice}
                            >
                                <MenuItem value={FILTER_PRICE.ALL_PRICES}>All Prices</MenuItem>
                                <MenuItem value={FILTER_PRICE.ONE}>$</MenuItem>
                                <MenuItem value={FILTER_PRICE.TWO}>$$</MenuItem>
                                <MenuItem value={FILTER_PRICE.THREE}>$$$</MenuItem>
                                <MenuItem value={FILTER_PRICE.FOUR}>$$$$</MenuItem>
                            </Select>
                            <div>Distance (up to)</div>
                            <Select
                                labelId="distance-label"
                                id="distance-id"
                                value={gameContext.filterDistance}
                                label="Distance"
                                onChange={gameContext.setFilterDistance}
                            >
                                <MenuItem value={FILTER_DISTANCE.DEFAULT}>Local Places</MenuItem>
                                <MenuItem value={FILTER_DISTANCE.HALF_MILE}>0.5 mi</MenuItem>
                                <MenuItem value={FILTER_DISTANCE.THREE_MILES}>3 mi</MenuItem>
                                <MenuItem value={FILTER_DISTANCE.FIVE_MILES}>5 mi</MenuItem>
                                <MenuItem value={FILTER_DISTANCE.TEN_MILES}>10 mi</MenuItem>
                                <MenuItem value={FILTER_DISTANCE.TWENTY_MILES}>20 mi</MenuItem>
                            </Select>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleDialogClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                )
                }
            </GameContext.Consumer>
        )
    }
}

export const FILTER_DISTANCE = {
    DEFAULT: "7",
    HALF_MILE: "0.5",
    THREE_MILES: "3",
    FIVE_MILES: "5",
    TEN_MILES: "10",
    TWENTY_MILES: "20"

}

export const FILTER_PRICE = {
    ALL_PRICES: 99,
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4
}

export default FilterDialog;