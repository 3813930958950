// Set the name of the hidden property and the change event for visibility
import gameContextService from "./service/GameContextService";
import {GameId} from "./context/GameProvider";

let hidden;
let visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}

function isDocumentHidden() {
    return document[hidden];
}

async function handleVisibilityChange() {
    // backgrounding
    if (isDocumentHidden()) {
        if (gameContextService) {
            gameContextService.disconnectSocket();
        }
    }
    // foregrounding
    else if (gameContextService) {
        const DISPLAY_PAGE = gameContextService.getDisplayPage();
        if(DISPLAY_PAGE.props.displayName === "PassengerList" || DISPLAY_PAGE.props.displayName === "InGame") {
            await gameContextService.connectToGame(true);
        }
    }
}

// Warn if the browser doesn't support addEventListener or the Page Visibility API
if (typeof document.addEventListener === "undefined" || hidden === undefined) {
    console.error("Page Visibility API not supported");
} else {
    // Handle page visibility change
    document.removeEventListener(visibilityChange, handleVisibilityChange);
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
}