import React, {Component} from 'react';
import "./TextFieldWithIcon.scss";
import FieldIcon from "../FieldIcon/FieldIcon";
import {TextField} from "@mui/material";
import PropTypes from "prop-types";
import ErrorIcon from '@mui/icons-material/Error';

class TextFieldWithIcon extends Component {
    render() {
        let errorClass = "";
        if(this.props.isValid) {
            errorClass = !this.props.isValid() ? "error" : "";
        }

        const errorMessage = this.props.helperText ? this.props.helperText : "Value is invalid";

        return (
            <div className={"textfield_container " + (this.props.className ? this.props.className : "")}>
                <FieldIcon
                    title={this.props.title}
                    icon={this.props.icon}
                    onClick={this.props.onButtonClick} />
                {!this.props.disabled ?
                    <div className={"full_width"}>
                        <TextField className={["rounded_input", errorClass].join(" ")}
                                   placeholder={this.props.placeholder}
                                   variant="outlined"
                                   multiline={false}
                                   fullWidth={false}
                                   size={"small"}
                                   autoFocus={this.props.autoFocus}
                                   value={this.props.value}
                                   onChange={this.props.onChange}
                                   onKeyDown={this.props.handleKeyDown}
                        />
                        {errorClass === "error" &&
                            <div title={errorMessage} className="error_icon">
                                <ErrorIcon />
                            </div>
                        }

                    </div>
                    :
                    <span className={"disabled"}>{this.props.value}</span>
                }
                {this.props.children}
            </div>
        )
    }
}

//TODO update proptypes

TextFieldWithIcon.propTypes = {
    handleKeyDown: PropTypes.func,
    title: PropTypes.string
}

TextFieldWithIcon.defaultProps = {
    handleKeyDown: function() {},
    title: ""
}

export default TextFieldWithIcon;