import React, {Component} from "react";
import styles from "./HomePage.module.scss";
import UserContext from "../../context/UserContext";
import GameContext from "../../context/GameContext";
import {the_lunch_train} from "../../images";
import User from "../../User";
import {Button} from "@mui/material";
import {GameId} from "../../context/GameProvider";
import Config from "../Admin/Config";
import NameEntry from "../NameEntry/NameEntry";
import About from "../About/About";
import conductor from "../../assets/users/conductor_icon.png";
import passenger from "../../assets/users/passenger_icon.png";
import about from "../../assets/users/about_icon.png";
import info from "../../assets/users/info_icon.png";
import common_styles from "../../components/Common/common.module.scss";
import googleBadge from "../../assets/google-play-badge-large.png";
import iphoneBadge from "../../assets/iphone-badge-large.png";
import yelpLogo from "../../assets/yelp_logo.png";
import Toast from "../../components/Common/Toast/Toast";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import gameContextService from "../../service/GameContextService";

class HomePage extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        // DOM references for animating text
        this.conductorDom = React.createRef();
        this.passengerDom = React.createRef();

        this.CONDUCTOR_TEXT = {
            "primary" : User.CONDUCTOR.getText(),
            "secondary": "Create Game"
        }

        this.PASSENGER_TEXT = {
            "primary": User.PASSENGER.getText(),
            "secondary": "Join Game"
        }

        this.state = {
            buttonState: "primary"
        }
    }

    componentDidMount() {
        this.context.resetGameState();
        this.context.updateYelpStatus();
        this.textTimer = setInterval(this.changeText, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.textTimer);
    }

    /**
     * Sets the state of the button text ("primary"/"secondary")
     */
    changeText = () => {
        this.setState(prevState => {
            return {
                buttonState: prevState.buttonState === "primary" ? "secondary" : "primary"
            }
        })
    }

    render() {
        const CONDUCTOR_TEXT = this.CONDUCTOR_TEXT[this.state.buttonState];
        const PASSENGER_TEXT = this.PASSENGER_TEXT[this.state.buttonState];

        return (
            <UserContext.Consumer>
                {userContext => (
                    <div className={common_styles.map_bg}>
                        {this.context.toastMessage &&
                            <Toast message={this.context.toastMessage}
                                   severity={this.context.toastSeverity} />}
                        <h2 className={common_styles.hidden}>The Lunch Train</h2>
                        <div className={styles.background} />
                        <div className={styles.container}>
                            <div className={styles.header}>
                                <img alt={"The Lunch Train"} src={the_lunch_train} className={styles.the_lunch_train}/>
                            </div>
                            <div className={styles.buttons}>
                                <div className={styles.actions}>
                                    <div className={styles.button_block}
                                         onClick={() => {
                                             if(this.context.hasYelpRequests()) {
                                                 this.context.setGameId(GameId.UNKNOWN);
                                                 this.context.clearErrors();
                                                 this.context.setSearchResults([]);
                                                 this.context.setPlacesInPlay([]);
                                                 userContext.setRole(User.CONDUCTOR);
                                                 this.context.setDisplayPage(<Config/>);
                                             } else {
                                                 const message = "We are out of Yelp requests for today :( Rest assured" +
                                                     " we are working on a better solution!";
                                                 this.context.setToastMessage(message, Toast.Severity.ERROR, 10000);
                                             }
                                         }}>
                                        {!this.context.hasYelpRequests() &&
                                            <div className={styles.block_yelp}>CLOSED FOR MAINTENANCE</div>
                                        }
                                        <img alt="Conductor" src={conductor} />
                                        <Button variant="contained"
                                                color={"secondary"}
                                                className={styles.user_button}>
                                            <span ref={this.conductorDom}>{CONDUCTOR_TEXT}</span>
                                        </Button>
                                    </div>

                                    <div className={styles.button_block}
                                         onClick={() => {
                                             userContext.setRole(User.PASSENGER);
                                             this.context.setDisplayPage(<NameEntry />);
                                         }}>
                                        <img alt="Passenger" src={passenger} />
                                        <Button variant="contained"
                                                className={styles.user_button}>
                                            <span ref={this.passengerDom}>{PASSENGER_TEXT}</span>
                                        </Button>
                                    </div>
                                </div>
                                <div className={styles.actions}>
                                    <div className={styles.button_block}
                                         onClick={() => {
                                             this.context.setDisplayPage(<About />);
                                         }}>
                                        <img alt="Help" src={about} className={styles.about_button}/>
                                        <Button variant="contained"
                                                color={"info"}
                                                className={styles.help_button}>
                                            HOW TO
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            {!window.isNativeApp &&
                                <div className={common_styles.app_store_icons}>
                                    <a href='https://play.google.com/store/apps/details?id=com.thelunchtrain.tlt&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                                       target="_blank">
                                        <img className={common_styles.store_icon}
                                             alt='Get it on Google Play'
                                             src={googleBadge}/>
                                    </a>
                                    <a href='https://apps.apple.com/app/the-lunch-train/id1629515045'
                                       target="_blank">
                                        <img className={common_styles.store_icon}
                                             alt='Download on the App Store'
                                             src={iphoneBadge}/>
                                    </a>
                                </div>
                            }
                            <div className={common_styles.powered_by}>
                                Powered by:
                                <img src={yelpLogo} />
                                <span className={common_styles.demo}
                                      onClick={() => gameContextService.openUrl("https://youtube.com/shorts/pV9boxJOxZk")}>
                                    <FontAwesomeIcon size={"lg"} icon={"film"} />
                                    <span className={styles.demo}>View demo</span>
                                </span>
                            </div>
                        </div>
                    </div>
                )
                }
            </UserContext.Consumer>
        );
    }
}

HomePage.defaultProps = {
    displayName: "HomePage"
}

export default HomePage;