import React, {Component} from "react";
import GameContext from "./context/GameContext";
import PropTypes from "prop-types";
import NameEntry from "./pages/NameEntry/NameEntry";
import {DISPLAY_TYPE} from "./context/GameProvider";
import {isMobile} from "react-device-detect";
import "./PageVisibility";

class MainApp extends Component {
    static contextType = GameContext;

    componentDidMount() {
        // the game id is specified, we are joining a game directly via the URL
        if(this.props.gameId) {
            this.context.setDisplayPage(<NameEntry {...this.props} />);
        }

        let displayType = DISPLAY_TYPE.MOBILE;
        if(!isMobile) {
            displayType = DISPLAY_TYPE.DESKTOP;
        }

        this.context.setDisplayType(displayType);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        Object.entries(this.props).forEach(([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        );
        if (this.state) {
            Object.entries(this.state).forEach(([key, val]) =>
                prevState[key] !== val && console.log(`State '${key}' changed`)
            );
        }
    }

    render() {
        return (
            this.context.displayPage
        );
    }
}

MainApp.propTypes = {
    gameId: PropTypes.string, // optional
}

MainApp.defaultProps = {
    gameId: "",
}

export default MainApp;
