import React, {Component} from 'react';
import styles from "./FieldIcon.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from "prop-types";

/**
 * a FontAwesome icon with a hoverable title display
 */
class FieldIcon extends Component {
    render() {
        const size = this.props.size ? styles[this.props.size] : styles.normal;
        const clickable = this.props.clickable ? styles.clickable : "";
        const disabled = this.props.disabled ? styles.disabled : "";
        const onClick = this.props.disabled ? function() {} : this.props.onClick;

        return (
            <span className={styles.icon} onClick={onClick}
                  title={this.props.title}>
                <span className={[styles.icon_bg, size, clickable, disabled, this.props.className].join(" ")}>
                    <FontAwesomeIcon
                        icon={this.props.icon}
                        className={styles.icon_img}
                        fixedWidth />
                    {this.props.children}
                </span>
            </span>
        )
    }
}

FieldIcon.propTypes = {
    className: PropTypes.string,
    clickable: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    size: PropTypes.string,
    title: PropTypes.string
}

FieldIcon.defaultProps = {
    className: "",
    clickable: false,
    disabled: false,
    icon: "",
    onClick: function() {},
    size: "normal",
    title: ""
}

export default FieldIcon;