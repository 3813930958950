import React, {Component} from "react";
import styles from "./Ticket.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import gameContextService from "../../../service/GameContextService";
import User from "../../../User";
import conductor from "../../../assets/users/conductor.png";
import passenger from "../../../assets/users/passenger.png";

class Ticket extends Component {
    render() {
        const ROLE = User.getRoleFromString(this.props.role);
        const USER_STYLE = ROLE === User.CONDUCTOR ? styles.conductor : styles.passenger;
        const USER_ICON = ROLE === User.CONDUCTOR ? conductor : passenger;
        const TICKET_STYLE = !this.props.online ? styles.offline : "";

        return (
            <div className={[styles.ticket_container, TICKET_STYLE].join(" ")}>
                {/* online status */}
                {!this.props.online &&
                    <div className={styles.status}>OFFLINE</div>
                }

                {/* left side perforation */}
                <ul className={styles.left_side_perf}>
                    {[...Array(6)].map((obj, i) => {
                        return <li key={i} />
                    })}
                </ul>

                {/* background user icon */}
                <div className={[styles.icon_bg, USER_STYLE].join(" ")}>
                    <img src={USER_ICON} />
                </div>

                {/* ticket top */}
                <div className={[styles.ticket_top, USER_STYLE].join(" ")}>
                    {[...Array(2)].map((obj, i) => {
                        return <div className={styles.game_id} key={i}>
                            {this.props.gameId}
                        </div>
                    })}
                </div>

                {/* user icons and name */}
                <div className={styles.ticket_content}>
                    {[...Array(2)].map((obj, i) => {
                        return <div className={[styles.user, i === 1 ? styles.user_small : ""].join(" ")} key={i}>
                            <FontAwesomeIcon icon={"user"}
                                             className={styles.user_icon}
                                             fixedWidth />
                            <div className={styles.name}>
                                {this.props.name}
                            </div>
                        </div>
                    })}
                </div>

                {/* ticket bottom content */}
                <div className={styles.ticket_content_2}>
                    <div>
                        <div className={styles.section_header}>TRAIN NAME</div>
                        <div>{this.props.trainName}</div>
                    </div>
                    <div>
                        <div className={styles.section_header}>ARRIVAL</div>
                        <div>{gameContextService.getMeetingTime()}</div>
                    </div>
                    <div>
                        <div className={styles.section_header}>TRAIN NAME</div>
                        <div>{this.props.trainName}</div>
                    </div>
                </div>

                {/* barcodes */}
                <div className={[styles.ticket_bottom, USER_STYLE].join(" ")}>
                    {[...Array(2)].map((obj, i) => {
                        return <div className={styles.barcode}  key={i} />
                    })}
                </div>

                {/* divider perforation */}
                <div className={styles.perforation} />

                {/* right side perforation */}
                <ul className={styles.right_side_perf}>
                    {[...Array(6)].map((obj, i) => {
                        return <li key={i} />
                    })}
                </ul>
            </div>
        )
    }
}

Ticket.propTypes = {
    gameId: PropTypes.string.isRequired,
    meetingTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    online: PropTypes.bool.isRequired,
    role: PropTypes.string.isRequired,
    trainName: PropTypes.string.isRequired,
}

Ticket.defaultProps = {
    gameId: "ABCDEF",
    meetingTime: "TBD",
    name: "John Doe",
    online: false,
    role: User.PASSENGER.toString(),
    trainName: "The Lunch Train"
}

export default Ticket;