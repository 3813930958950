import React, {Component} from "react";
import {Button, Skeleton} from "@mui/material";
import common_styles from "../../Common/common.module.scss";
import styles from "./PassengerHeader.module.scss";
import GameContext from "../../../context/GameContext";
import ShareIcon from "@mui/icons-material/Share";
import IconWithText from "../../Common/IconWithText/IconWithText";
import gameContextService from "../../../service/GameContextService";
import Toast from "../../Common/Toast/Toast";
import {DISPLAY_TYPE} from "../../../context/GameProvider";

class PassengerHeader extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        });
    }

    /**
     * If sharing isn't applicable, copy the invitation to the clipboard
     */
    copyInvitation = async () => {
        let message = "Invitation copied to clipboard. Send to your passengers!"
        const invitation = gameContextService.getInvitation();
        let failure = false;

        if("clipboard" in navigator) {
            await navigator.clipboard.writeText(invitation);
        } else {
            message = "Copying to clipboard not supported. Send the following game ID to your passengers: " + this.context.gameId;
            failure = true;
        }

        this.context.setToastMessage(message, failure ? Toast.Severity.ERROR : Toast.Severity.SUCCESS);
    }

    /**
     * Shares the game link using the native OS share option (or copy to clipboard if fails)
     */
    shareInvitation = async () => {
        const invitation = gameContextService.getInvitation();
        const self = this;

        // share using native OS
        if ("share" in navigator && this.context.displayType !== DISPLAY_TYPE.DESKTOP) {
            navigator.share({
                title: "The Lunch Train",
                text: invitation
            }).catch(error => function () {
                self.copyInvitation();
            });
        } else {
            // try native OS WebView
            if(window.isNativeApp) {
                window.dispatchEvent(new CustomEvent("tlt_invite", {"detail": {invitation}}));
            } else {
                await this.copyInvitation();
            }
        }
    }

    render() {
        return (
            this.state.isMounted ?
                <div className={styles.container}>
                    <div className={common_styles.header_row}>
                        <div>
                            {/* game id */}
                            <IconWithText icon={"passport"}
                                          title={"Game ID"}
                                          className="step-game-id"
                                          fontSize={"medium"}
                                          text={this.context.gameId} />
                        </div>
                        <div className={styles.centered}>
                            {this.props.children}

                            {/* INVITE button */}
                            <Button variant="contained"
                                    title="Invite passenger(s)"
                                    className={[styles.invite_button, "step-invite"].join(" ")}
                                    onClick={this.shareInvitation}
                                    endIcon={<ShareIcon/>}>INVITE</Button>
                        </div>
                    </div>

                    <div className={common_styles.header_row}>
                        {/* train name */}
                        <IconWithText icon={"train-subway"}
                                      title={"Train name"}
                                      fontSize={"medium"}
                                      text={this.context.trainName} />
                    </div>
                </div>
                :
                <Skeleton variant="rectangular" height="100%" />
        );
    }
}

export default PassengerHeader;