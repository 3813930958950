import React, {Component} from "react";
import {Skeleton} from "@mui/material";
import common_styles from "../../Common/common.module.scss";
import styles from "./InGameHeader.module.scss";
import GameContext from "../../../context/GameContext";
import UserContext from "../../../context/UserContext";
import IconWithText from "../../Common/IconWithText/IconWithText";
import {ANIMATE as Animate} from "../../../Animate";
import * as Events from "events";
import FieldIcon from "../../Common/FieldIcon/FieldIcon";
import {GameState} from "../../../context/GameProvider";

class InGameHeader extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        // name reference
        this.nameRef = undefined;

        this.state = {
            isMounted: false,
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        }, () => {
            if(this.nameRef) {
                this.nameRef.addEventListener(Events.PLAYER_CHANGED, this.animateName);
            }
        });
    }

    componentWillUnmount() {
        if(this.nameRef) {
            this.nameRef.removeEventListener(Events.PLAYER_CHANGED, this.animateName);
        }
    }

    /**
     * @returns {*} the time left for the current player's turn (as a string)
     */
    getTimeLeft = () => {
        let timeLeft = this.context.timeLeft;
        if(this.context.winningPlace && this.playerTimer) {
            timeLeft = 0;
        }

        return String(timeLeft).padStart(2, "0");
    }

    /**
     * Animates the Name value on player change
     */
    animateName = () => {
        const dom = this.nameRef;
        if(dom) {
            dom.classList.add(Animate.ANIMATED);
            dom.classList.add(Animate.BOUNCE_IN_LEFT);
            dom.addEventListener(Animate.EVENT_ANIMATION_END, () => {
                dom.classList.remove(Animate.ANIMATED);
                dom.classList.remove(Animate.BOUNCE_IN_LEFT);
            });
        }
    }

    render() {
        return (
            this.state.isMounted ?
                <UserContext.Consumer>
                    {userContext => {
                        return (
                            <div className={styles.container}>
                                <div className={common_styles.header_row}>
                                    {/* current player */}
                                    <IconWithText icon={"user"}
                                                  id="in-game-name-id"
                                                  className="step-player-turn"
                                                  ref={(e) => this.nameRef = e}
                                                  text={this.context.getCurrentPlayerName(userContext)} />

                                    <div className={common_styles.header_section}>
                                        {/* help button */}
                                        {this.context.getGameState() !== GameState.GAME_OVER &&
                                            <FieldIcon icon={"circle-question"}
                                            title="Help"
                                            className={[common_styles.help_button, common_styles.normal].join(" ")}
                                            onClick={this.props.openTour} />
                                        }

                                        {/* player turn timer */}
                                        <IconWithText icon={"clock"}
                                                      className={[styles.pad_right, "step-timer"].join(" ")}
                                                      text={this.getTimeLeft()}
                                                      bold />
                                    </div>
                                </div>
                                <div className={common_styles.header_row}>
                                {/* train name */}
                                <IconWithText icon={"train-subway"}
                                              text={this.context.trainName} />
                                </div>
                            </div>
                        )
                    }}
                </UserContext.Consumer>
                :
                <Skeleton variant="rectangular" height="100%" />
        );
    }
}

export default InGameHeader;