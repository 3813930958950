import React, {Component, useContext} from "react";

import UserProvider from "./context/UserProvider";
import GameProvider from "./context/GameProvider";
import MainApp from "./MainApp";
import {BrowserRouter, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import User from "./User";
import UserContext from "./context/UserContext";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Privacy from "./pages/Privacy/Privacy";

const customTheme = createTheme({
    palette: {
        primary: {
            main: "#FFA726"
        },
        secondary: {
            main: "#99cc33"
        },
        about: {
            main: "#607D8B"
        }
    }
});

class App extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        Object.entries(this.props).forEach(([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        );
        if (this.state) {
            Object.entries(this.state).forEach(([key, val]) =>
                prevState[key] !== val && console.log(`State '${key}' changed`)
            );
        }
    }

    render() {
        // handles navigating to /join/gameId
        const MainAppWrapper = () => {
            const navigate = useNavigate();
            const location = useLocation();
            const params = useParams();
            const userContext = useContext(UserContext);

            React.useEffect(() => {
                userContext.setRole(User.PASSENGER);
                navigate("/", {replace: true});
            }, [location]);

            return <MainApp {...params} />
        }

        /**
         * Handle page back and page refresh
         */
        const initPageChange = () => {
            window.onbeforeunload = (event) => {
                const e = event || window.event;
                e.preventDefault();
                if (e) {
                    e.returnValue = ''
                }
            }
        };

        window.onload = function() {
            initPageChange();
        };

        return (
            <ThemeProvider theme={customTheme}>
                <GameProvider>
                    <UserProvider>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" exact element={<MainApp />}/>
                                <Route path="/join/:gameId" element={<MainAppWrapper />}/>
                                <Route path="/privacy" element={<Privacy />}/>
                                <Route path="*" element={<div>Page not found</div>} />
                            </Routes>
                        </BrowserRouter>
                    </UserProvider>
                </GameProvider>
            </ThemeProvider>
        )
    }
}

/**
 * <div>
 *                 <MediaQuery maxWidth={768}>
 *                     <Mobile />
 *                 </MediaQuery>
 *                 <MediaQuery minWidth={769} maxWidth={1224}>
 *                     <Tablet />
 *                 </MediaQuery>
 *                 <MediaQuery minWidth={1224}>
 *                     <Desktop />
 *                 </MediaQuery>
 *             </div>
 */

export default App;
