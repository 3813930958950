import React, {Component} from "react";
import styles from "./GameContent.module.scss";
import common_styles from "../../Common/common.module.scss";
import GameContext from "../../../context/GameContext";
import GameRow from "../GameRow/GameRow";
import _ from "lodash";
import FullElementSpinner from "../../Common/Spinner/FullElementSpinner";
import {GameState, PlaceStatus} from "../../../context/GameProvider";
import gameContextService from "../../../service/GameContextService";
import {ANIMATE} from "../../../Animate";
import GameWinner from "../GameWinner/GameWinner";
import Config from "../../../pages/Admin/Config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IconWithText from "../../Common/IconWithText/IconWithText";
import PropTypes from "prop-types";
import PassengerDialog from "../../Common/PassengerDialog/PassengerDialog";
import Avatars from "../Avatars/Avatars";

class GameContent extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        // Ref used for end game state
        this.containerRef = undefined;

        this.state = {
            displayWinner: false,
            isFiltered: false,
            isUpdatingGame: false,
            openDialog: false,
            searchClicked: false
        }
    }

    /**
     * Returns the array of active places in play
     * @returns {[]}
     */
    getPlacesInPlay = () => {
        return _.filter(this.context.placesInPlay, {"status": PlaceStatus.ACTIVE});
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.context.currentPlayerId !== nextContext.currentPlayerId ||
            (this.context.gameState === GameState.NOT_STARTED && this.props.isTourOpen)
            || this.state.openDialog !== nextState.openDialog;
    }

    componentDidMount() {
        // add search event listener so we can clear the filter
        document.addEventListener(Event.SEARCH_CLICKED, this.searchClicked);

        if(this.context.gameState === GameState.IN_GAME) {
            this.setState({
                doSortResults: true,
                isUpdatingGame: true,
                searchClicked: false
            }, async () => {
                await gameContextService.updateGameContext();
                this.setState({
                    isUpdatingGame: false
                }, () => {
                    this.context.setVisiblePlaces(this.context.placesInPlay)
                });
            });
        } else {
            const places = this.context.placesInPlay;
            let isFiltered = false;

            if(this.context.placesInPlay.length > 0) {
                isFiltered = true;
            }
            this.setState({
                isFiltered: isFiltered
            }, () => {
                this.context.setVisiblePlaces(places);
            });
        }

        // add the end game event
        if(this.containerRef) {
            this.containerRef.addEventListener(ANIMATE.EVENT_ANIMATION_END, () => {
                this.setState({
                    displayWinner: true,
                });
            });
        }
    }

    componentWillUnmount() {
        this.context.setSearchResults([]);
        document.removeEventListener(Event.SEARCH_CLICKED, this.searchClicked);
    }

    /**
     * handles response to clicking on the Search button
     */
    searchClicked = () => {
        this.setState({
            isFiltered: false,
            searchClicked: true
        });
    }

    /**
     * Renders the game rows dependent upon the current game state
     * @returns {*[]} an array of React Components
     */
    renderGameRows = () => {
        const rows = [];

        this.context.doSortResults ?
            // display search results
            _.sortBy(this.context.visiblePlaces, ["name", "distance"])
                .map((obj, index) => (
                    rows.push(<GameRow key={obj.id} {...obj} index={index} />)
                ))
            :
            // display in game results
            _.map(this.context.visiblePlaces, function(obj, index) {
                rows.push(<GameRow key={obj.id} status={obj.status} {...obj} index={index} />)
            });

        if(this.props.isTourOpen && rows.length === 0) {
            rows.push(<GameRow key="unique" index={0} />);
        }

        return rows;
    }

    /**
     * Opens the Passenger List dialog
     */
    handleDialogOpen = () => {
        this.setState({
            openDialog: true
        });
    }

    handleDialogClose = () => {
        this.setState({
            openDialog: false
        });
    }

    renderContent = () => {
        // if we are returning from another screen
        if(this.state.isUpdatingGame) {
            return <FullElementSpinner message={"Updating..."} />
        }

        // if we're viewing the tutorial or we're searching
        if(this.props.isTourOpen || this.context.searchResults.length !== 0) {
            return this.renderGameRows();
        }

        // no search results
        if(this.state.searchClicked && this.context.searchResults.length === 0) {
            return <div className={[common_styles.map_bg, styles.places_empty].join(" ")}>
                <span>No restaurants found matching your search</span>
            </div>
        }

        // no restaurant selections made
        if(this.context.placesInPlay.length === 0 && this.context.searchResults.length === 0) {
            return <div className={[common_styles.map_bg, styles.places_empty].join(" ")}>
                <span>Use the fields above to find restaurants in your area</span>
                <span>Add {Config.MIN_PLACES_SELECTED} or more restaurants to continue</span>
            </div>
        }

        return this.renderGameRows();
    }

    render() {
        // className for when the game has ended
        const isGameOver = this.context.winningPlace ? [ANIMATE.ANIMATED, ANIMATE.FADE_OUT].join(" ") : "";
        const isRestaurantsClickable = this.context.gameState !== GameState.IN_GAME
            && this.context.gameState !== GameState.GAME_OVER
            && this.getPlacesInPlay().length > 0;

        let block_style = [common_styles.block_header, "step-restaurants-header"].join(" ");
        if(this.context.gameState === GameState.IN_GAME) {
            block_style += " " + common_styles.block_header_darker;
        }

        return (
            <React.Fragment>
                <div className={block_style}>
                    <div>RESTAURANTS</div>

                    {/* display the avatars if we're in game*/}
                    {this.context.gameState === GameState.IN_GAME && <Avatars />}

                    {/* places selected */}
                    <div className={common_styles.block_section}>
                        {this.state.isFiltered && this.context.gameState !== GameState.IN_GAME &&
                            <FontAwesomeIcon
                                title={"Return to search results"}
                                icon={"rotate-left"}
                                className={styles.clickable}
                                onClick={(e) =>
                                    this.setState({
                                        isFiltered: false,
                                        searchClicked: true
                                    }, this.context.setVisiblePlaces(this.context.searchResults, e))}/>
                        }
                        {this.context.gameState === GameState.IN_GAME &&
                            <div>
                                <div className={styles.clickable}
                                     onClick={this.handleDialogOpen}>
                                    <IconWithText icon="user"
                                                  bold
                                                  style={"no-bg"}
                                                  className={styles.clickable}
                                                  title={this.context.passengers.length + " passenger(s)"}
                                                  text={this.context.passengers.length.toString()}/>
                                </div>
                                <PassengerDialog open={this.state.openDialog}
                                                 handleDialogClose={this.handleDialogClose}/>
                            </div>
                        }
                        <IconWithText icon="utensils"
                                      title={this.getPlacesInPlay().length + " place(s) in play"}
                                      bold
                                      style={"no-bg"}
                                      className={ isRestaurantsClickable ? styles.clickable : "step-restaurants-header" }
                                      onClick={(e) =>
                                          isRestaurantsClickable && this.setState({
                                              isFiltered: true,
                                              searchClicked: false
                                          }, async function() {
                                              await this.context.setDoSortResults(true);
                                              this.context.setVisiblePlaces(this.context.placesInPlay, e)
                                          })}
                                      text={this.getPlacesInPlay().length.toString()} />
                    </div>
                </div>

                {/* WINNER! */}
                {this.context.winningPlace && this.state.displayWinner &&
                    <div className={styles.center_content}>
                        <GameWinner {...this.context.winningPlace} />
                    </div>
                }

                <div className={[styles.game_content, isGameOver, "step-restaurants-list"].join(" ")}
                     ref={(e) => this.containerRef = e}>
                    {this.context.isSearching ?
                        <FullElementSpinner message={"Searching..."} />
                        :
                        this.renderContent()
                    }
                </div>
            </React.Fragment>
        )
    }
}

GameContent.propTypes = {
    isTourOpen: PropTypes.bool
}

GameContent.defaultProps = {
    isTourOpen: false
}

export default GameContent;