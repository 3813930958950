import React, {Component} from "react";
import {Skeleton} from "@mui/material";
import SearchHeader from "../../components/SearchHeader/SearchHeader";
import GameContent from "../../components/Game/GameContent/GameContent";
import common_styles from "../../components/Common/common.module.scss";
import GameContext from "../../context/GameContext";
import NameEntry from "../NameEntry/NameEntry";
import Toast from "../../components/Common/Toast/Toast";
import JoyRide, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TOUR_GUIDE_OPTIONS} from "../../components/Common/tour_guide";
import HomePage from "../HomePage/HomePage";
import FieldIcon from "../../components/Common/FieldIcon/FieldIcon";

class Config extends Component {
    static contextType = GameContext;
    static MIN_PLACES_SELECTED = 5;

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            isTourOpen: false,
            stepIndex: 0
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        Object.entries(this.props).forEach(([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        );
        if (this.state) {
            Object.entries(this.state).forEach(([key, val]) =>
                prevState[key] !== val && console.log(`State '${key}' changed`)
            );
        }
    }

    /**
     * Determine whether the Conductor can proceed to the next step
     * @returns {boolean} true if we can continue, false otherwise
     */
    canContinue = () => {
        return this.context.placesInPlay.length >= Config.MIN_PLACES_SELECTED;
    }

    openTour = () => {
        this.setState({
            isTourOpen: true
        });
    }

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.setState({
                stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
            });
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.setState({
                isTourOpen: false,
                stepIndex: 0
            });
        }
    };

    render() {
        const canContinue = this.canContinue();

        return (
            this.state.isMounted ?
                <React.Fragment>
                    <div className={common_styles.game_container}>
                        <JoyRide
                            steps={this.TOUR_STEPS}
                            stepIndex={this.state.stepIndex}
                            callback={this.handleJoyrideCallback}
                            continuous
                            showSkipButton
                            disableScrolling
                            run={this.state.isTourOpen}
                            locale={{last: "Close"}}
                            styles={{options: TOUR_GUIDE_OPTIONS}}
                        />
                        {this.context.toastMessage &&
                            <Toast message={this.context.toastMessage}
                                   severity={this.context.toastSeverity} />}
                        <SearchHeader openTour={this.openTour} />
                        <GameContent isTourOpen={this.state.isTourOpen} />
                        <div className={[common_styles.action_button_container, common_styles.dark].join(" ")}>
                            {/* BACK */}
                            <div>
                                <FieldIcon icon={"rotate-left"}
                                           clickable={true}
                                           onClick={() => this.context.setDisplayPage(<HomePage />)}
                                           size={"large"}
                                           title="Go back" />
                            </div>

                            {/* CONTINUE */}
                            <div className={"step-next-button"}>
                                <FieldIcon icon={"angle-right"}
                                           clickable={true}
                                           disabled={(!this.state.isTourOpen || this.state.stepIndex !== this.TOUR_STEPS.length - 1)
                                               && !canContinue}
                                           onClick={() => {
                                               if (!this.state.isTourOpen) {
                                                   this.context.setDisplayPage(<NameEntry/>)
                                               }
                                           }}
                                           size={"large"}
                                           title="Continue" />
                            </div>
                        </div>
                    </div>
                </React.Fragment>
                :
                <Skeleton variant="rectangular" height="100%" />
        );
    }

    TOUR_STEPS = [
        {
            target: ".step-cuisine",
            content: "Are you looking for a specific restaurant or cuisine role?",
        },
        {
            target: ".step-location",
            content: "Where do you want to search? Select the dropdown arrow to use the current location",
        },
        {
            target: ".step-search",
            content: "Find some restaurants!"
        },
        {
            target: ".step-game-row-action",
            content: "Click on the '+' or '-' button to add or remove a restaurant to/from your game"
        },
        {
            target: ".step-restaurants-header",
            content: "Add 5 or more restaurants to continue"
        },
        {
            target: ".step-next-button",
            content: "After making your selections, click on the CONTINUE button to continue"
        }
    ]
}

Config.defaultProps = {
    displayName: "Config"
}

export default Config;