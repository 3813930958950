import {Component} from "react";
import styles from "./Spinners.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

class FullElementSpinner extends Component {
    render() {
        return (
            <div className={styles.full_screen}>
                <div className={styles.spinner_block}>
                    <CircularProgress
                        className={styles.spinner}
                        size={64} />
                    {this.props.message &&
                        <div className={styles.message}>{this.props.message}</div>
                    }
                </div>
            </div>
        )
    }
}


FullElementSpinner.propTypes = {
    message: PropTypes.string
}

FullElementSpinner.defaultProps = {
    message: ""
}

export default FullElementSpinner;