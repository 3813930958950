export const american = require("./assets/place_types/food-american.png");
export const bakery = require("./assets/place_types/food-bakery.png");
export const bbq = require("./assets/place_types/food-bbq.png");
export const beer = require("./assets/place_types/food-beer.png");
export const breakfast = require("./assets/place_types/food-breakfast.png");
export const burgers = require("./assets/place_types/food-burgers.png");
export const champagne = require("./assets/place_types/food-champagne.png");
export const chinese = require("./assets/place_types/food-chinese.png");
export const cocktails = require("./assets/place_types/food-cocktails.png");
export const coffee = require("./assets/place_types/food-coffee.png");
export const creperies = require("./assets/place_types/food-creperies.png");
export const dance = require("./assets/place_types/food-dance.png");
export const delis = require("./assets/place_types/food-delis.png");
export const dessert = require("./assets/place_types/food-dessert.png");
export const donuts = require("./assets/place_types/food-donuts.png");
export const european = require("./assets/place_types/food-european.png");
export const filipino = require("./assets/place_types/food-filipino.png");
export const food_truck = require("./assets/place_types/food-food-truck.png");
export const french = require("./assets/place_types/food-french.png");
export const generic = require("./assets/place_types/food-generic.png");
export const german = require("./assets/place_types/food-german.png");
export const greek = require("./assets/place_types/food-greek.png");
export const guitar = require("./assets/place_types/food-guitar.png");
export const halal = require("./assets/place_types/food-halal.png");
export const hispanic = require("./assets/place_types/food-hispanic.png");
export const hot_dogs = require("./assets/place_types/food-hot-dogs.png");
export const indian = require("./assets/place_types/food-indian.png");
export const irish = require("./assets/place_types/food-irish.png");
export const italian = require("./assets/place_types/food-italian.png");
export const japanese = require("./assets/place_types/food-japanese.png");
export const korean = require("./assets/place_types/food-korean.png");
export const mediterranean = require("./assets/place_types/food-mediterranean.png");
export const noodles = require("./assets/place_types/food-noodles.png");
export const pizza = require("./assets/place_types/food-pizza.png");
export const ramen = require("./assets/place_types/food-ramen.png");
export const russian = require("./assets/place_types/food-russian.png");
export const salad = require("./assets/place_types/food-salad.png");
export const sandwiches = require("./assets/place_types/food-sandwiches.png");
export const seafood = require("./assets/place_types/food-seafood.png");
export const soup = require("./assets/place_types/food-soup.png");
export const steak = require("./assets/place_types/food-steak.png");
export const tacos = require("./assets/place_types/food-tacos.png");
export const turkish = require("./assets/place_types/food-turkish.png");
export const vietnamese = require("./assets/place_types/food-vietnamese.png");
export const wine = require("./assets/place_types/food-wine.png");
