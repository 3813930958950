/**
 * Identifies the types of user that can exist within a game
 */
class User {
    // controls the action
    static CONDUCTOR = new User("conductor", "CONDUCTOR");

    // participates in game
    static PASSENGER = new User("passenger", "PASSENGER");

    constructor(role, text) {
        this.role = role;
        this.text = text;
    }

    toString() {
        return "User." + this.role;
    }

    getRole() {
        return this.role;
    }

    static getRoleFromString(role) {
        let result = User.PASSENGER;
        if(role && role.toLowerCase() === User.CONDUCTOR.role) {
            result = User.CONDUCTOR;
        }

        return result;
    }

    getText() {
        return this.text;
    }
}

export default User;