// animation.css classes/events
export const ANIMATE = {
    // classes
    ANIMATED: "animate__animated",
    BACK_OUT_LEFT: "animate__backOutLeft",
    BOUNCE_IN_LEFT: "animate__bounceInLeft",
    BOUNCE_IN_RIGHT: "animate__bounceInRight",
    BRING_BACK: "_bringback_animation",
    FADE_OUT: "animate__fadeOut",
    FLASH: "_flash_animation",
    HEARTBEAT: "animate__heartBeat",
    REMOVE: "_remove_animation",
    TADA: "animate__tada",

    // events
    EVENT_ANIMATION_END: "animationend"
}