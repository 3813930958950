import React, {forwardRef} from "react";
import styles from "./DateTime.module.scss";
import "./DateTime.override.css";
import ErrorIcon from "@mui/icons-material/Error";

/**
 * DateTime picker used to configure the meet time
 */
const DateTime = forwardRef(({ value, error, onChange, onClick }, ref) => {
    const errorClazz = error ? styles.error : "";

    return (
        <div className={styles.full_width}>
            <input value={value}
                   className={[styles.rounded_input, errorClazz].join(" ")}
                   onChange={onChange}
                   onClick={onClick}
                   ref={ref} />

            {error &&
                <div title="Date must be at a point in the future" className={styles.error_icon}>
                    <ErrorIcon />
                </div>
            }
        </div>
    )
});

export default DateTime;