import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import common_styles from "../common.module.scss";
import gameContextService from "../../../service/GameContextService";
import PropTypes from "prop-types";

class IconWithText extends Component {
    render() {
        let className = [common_styles.train_icon_block];

        switch(this.props.fontSize) {
            case "small":
                className.push(common_styles.train_block_small);
                break;
            case "medium":
                className.push(common_styles.train_block_medium);
                break;
            case "large":
                className.push(common_styles.train_block_large);
                break;
            default:
                className.push(common_styles.train_block_medium);
                break;
        }

        this.props.bold && className.push(common_styles.train_block_bold);
        this.props.style === "no-bg" && className.push(common_styles.train_block_no_bg);
        this.props.className && className.push(this.props.className);

        className = className.join(" ");

        return (
            <div className={className}
                 ref={this.props.innerRef}
                 id={this.props.id ? this.props.id : ""}
                 title={this.props.title}
                 onClick={this.props.onClick ? this.props.onClick : () => {}}>
                <FontAwesomeIcon icon={this.props.icon}
                                 className={common_styles.train_icon}
                                 fixedWidth />
                {gameContextService.getTrainText(this.props.text)}
            </div>
        )
    }
}

IconWithText.propTypes = {
    bold: PropTypes.bool, // bold text
    className: PropTypes.string,
    fontSize: PropTypes.string, // small, medium, large
    id: PropTypes.string,
    icon: PropTypes.string.isRequired, // fontawesome icon
    innerRef: PropTypes.func,
    onClick: PropTypes.func,
    style: PropTypes.string, // no-bg (no orange background)
    text: PropTypes.string.isRequired, // text to display
    title: PropTypes.string
}

IconWithText.defaultProps = {
    text: ""
}

export default React.forwardRef((props, ref) =>
    <IconWithText innerRef={ref} {...props} />
);