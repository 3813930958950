import React, {Component} from "react";
import {CircularProgress, Skeleton} from "@mui/material";
import common_styles from "../../components/Common/common.module.scss";
import GameContext from "../../context/GameContext";
import UserContext from "../../context/UserContext";
import styles from "./PassengerList.module.scss";
import PassengerRow from "../../components/Passenger/PassengerRow/PassengerRow";
import _ from "lodash";
import User from "../../User";
import NameEntry from "../NameEntry/NameEntry";
import gameContextService from "../../service/GameContextService";
import FullElementSpinner from "../../components/Common/Spinner/FullElementSpinner";
import PassengerHeader from "../../components/Passenger/PassengerHeader/PassengerHeader";
import Toast from "../../components/Common/Toast/Toast";
import IconWithText from "../../components/Common/IconWithText/IconWithText";
import JoyRide, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import FieldIcon from "../../components/Common/FieldIcon/FieldIcon";
import {TOUR_GUIDE_OPTIONS} from "../../components/Common/tour_guide";

class PassengerList extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.pageRef = React.createRef();

        this.state = {
            isMounted: false,
            isTourOpen: false,
            stepIndex: 0
        }
    }

    componentDidMount() {
        gameContextService.updateGameContext();

        this.setState({
            isMounted: true
        });
    }

    /**
     * Determine whether the Conductor can proceed to the next step
     * @param gameContext the GameContext
     * @returns {boolean} true if there are 2 or more players, false otherwise
     */
    canContinue = (gameContext) => {
        const passengers = gameContext.getPassengersOnline();
        return passengers.length >= 2;
    }

    /**
     * Removes the current player by navigating user to the Home Page or rickroll
     */
    removePlayerLocally = () => {
        window.onbeforeunload = function() {};
        window.open("https://www.youtube.com/watch?v=dQw4w9WgXcQ", "_self");
    }

    openTour = () => {
        this.setState({
            isTourOpen: true
        });
    }

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.setState({
                stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
            });
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.setState({
                isTourOpen: false,
                stepIndex: 0
            });
        }
    };

    render() {
        return (
            <GameContext.Consumer>
                {gameContext => {
                    const canContinue = this.canContinue(gameContext);
                    const passengers = gameContext.getPassengers();
                    const passengersOnline = gameContext.getPassengersOnline();
                    const passengersOffline = gameContext.getPassengersOffline();

                    return (
                        <UserContext.Consumer>
                            {userContext => {
                                const tourSteps = userContext.role === User.CONDUCTOR ? this.TOUR_STEPS_CONDUCTOR : this.TOUR_STEPS_PASSENGER;

                                // remove player if necessary
                                if(gameContext.removePlayerId === userContext.userId) {
                                    this.removePlayerLocally();
                                }

                                return (
                                    <div className={common_styles.game_container} ref={this.pageRef}>
                                        <JoyRide
                                            steps={tourSteps}
                                            stepIndex={this.state.stepIndex}
                                            callback={this.handleJoyrideCallback}
                                            continuous
                                            showSkipButton
                                            run={this.state.isTourOpen}
                                            locale={{last: "Close"}}
                                            styles={{options: TOUR_GUIDE_OPTIONS}}
                                        />

                                        {/* game start spinner */}
                                        {gameContext.isStartingGame &&
                                            <FullElementSpinner message={"Starting game..."}/>
                                        }

                                        {/* toast message */}
                                        {gameContext.toastMessage &&
                                            <Toast message={gameContext.toastMessage}
                                                   severity={gameContext.toastSeverity} />}

                                        {/* invite button */}
                                        <PassengerHeader>
                                            {/* help button */}
                                            <FieldIcon icon={"circle-question"}
                                                       title="Help"
                                                       className={[common_styles.help_button, common_styles.normal].join(" ")}
                                                       onClick={this.openTour} />
                                        </PassengerHeader>

                                        {/* content */}
                                        <div className={[common_styles.block_header, "step-train-info"].join(" ")}>
                                            <div>TRAIN INFORMATION</div>
                                            {/* places selected */}
                                            <div className={common_styles.block_section}>
                                                <IconWithText icon="rotate-left"
                                                              className={styles.refresh_passengers}
                                                              bold
                                                              style={"no-bg"}
                                                              onClick={() => gameContextService.updatePassengerList()}
                                                              title={"Refresh passenger list"} />
                                                <IconWithText icon="user"
                                                              bold
                                                              style={"no-bg"}
                                                              title={passengers.length + " passenger(s)"}
                                                              text={passengers.length.toString()} />
                                                <IconWithText icon="utensils"
                                                              bold
                                                              style={"no-bg"}
                                                              title={gameContext.placesInPlay.length + " places in play"}
                                                              text={gameContext.placesInPlay.length.toString()} />
                                            </div>
                                        </div>
                                        <div className={styles.passenger_list}>
                                            {
                                                _.orderBy(
                                                    passengers,
                                                    [(passenger) => passenger.name.toLowerCase()],
                                                    ["asc"]
                                                ).map((obj, index) => (
                                                    <PassengerRow key={obj.id}
                                                                  {...obj} />
                                                ))
                                            }

                                            {userContext.role === User.CONDUCTOR && passengersOnline.length < 2 &&
                                                <div className={styles.waiting}>
                                                    <CircularProgress size={72} color={"info"}/>
                                                    <div>Waiting for at least 1 more player...</div>
                                                    <div className={styles.warning}>
                                                        Please wait for all players to be online before starting
                                                    </div>
                                                </div>}
                                            {passengersOffline.length > 0 &&
                                                <div className={styles.warning}>
                                                    If using the web application and you are OFFLINE, try switching
                                                    between browser tabs to come back ONLINE
                                                </div>
                                            }
                                            {userContext.role === User.PASSENGER &&
                                                <div className={[styles.waiting, styles.passenger, "step-waiting"].join(" ")}>
                                                    <CircularProgress size={72} color={"info"}/>
                                                    <div>Waiting for the conductor to start the train...</div>
                                                </div>}
                                        </div>

                                        {/* footer */}
                                        <div className={[common_styles.action_button_container, common_styles.dark].join(" ")}>
                                            {/* BACK */}
                                            <div>
                                                <FieldIcon icon={"rotate-left"}
                                                           clickable={true}
                                                           onClick={async () => {
                                                               if(userContext.role === User.PASSENGER) {
                                                                   gameContextService.removePassenger(userContext.userId);
                                                               }
                                                               gameContext.setDisplayPage(<NameEntry />);
                                                           }}
                                                           size={"large"}
                                                           title="Go back" />
                                            </div>

                                            {/* CONTINUE */}
                                            {userContext.role === User.CONDUCTOR &&
                                                <div className={"step-next-button"}>
                                                    <FieldIcon icon={"train-subway"}
                                                               clickable={true}
                                                               disabled={(!this.state.isTourOpen || this.state.stepIndex !== tourSteps.length - 1)
                                                                   && !canContinue}
                                                               onClick={() => {
                                                                   gameContextService.startGame();
                                                               }}
                                                               size={"large"}
                                                               title="Depart"/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                )
                            }}
                        </UserContext.Consumer>
                    )
                }}
            </GameContext.Consumer>
        );
    }

    TOUR_STEPS_CONDUCTOR = [
        {
            target: ".step-invite",
            content: "Send this link to all your passengers!"
        },
        {
            target: ".step-game-id",
            content: "Or they can use this game ID on the Passenger Name Entry screen",
        },
        {
            target: ".step-train-info",
            content: "The number of passengers and restaurants in this game"
        },
        {
            target: ".step-next-button",
            content: "When everyone is aboard, click on the DEPART button to start the train!"
        }
    ]

    TOUR_STEPS_PASSENGER = [
        {
            target: ".step-invite",
            content: "Send this link to all your passengers!"
        },
        {
            target: ".step-train-info",
            content: "The number of passengers and restaurants in this game"
        },
        {
            target: ".step-waiting",
            content: "Wait for the conductor to start the game!"
        }
    ]
}

PassengerList.defaultProps = {
    displayName: "PassengerList"
}

export default PassengerList;