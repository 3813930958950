import {Alert, Snackbar} from "@mui/material";
import React, {Component} from "react";
import PropTypes from "prop-types";
import GameContext from "../../../context/GameContext";
import {GameState} from "../../../context/GameProvider";

/**
 * Dynamic Snackbar to display messages within the app\n
 * Defaults to an "error" Toast
 */
class Toast extends Component {
    static contextType = GameContext;

    static DEFAULT_DURATION = 5000;

    static Severity = {
        ERROR: "error",
        INFO: "info",
        SUCCESS: "success",
        WARNING: "warning"
    }

    static MessageType = {
        DEFAULT: "DEFAULT",
        FEEDBACK: "FEEDBACK",
        PLAYER_MOVE: "PLAYER_MOVE"
    }

    onClose = () => {
        this.context.setToastMessage("");
    }

    render() {
        const message = this.props.message;

        return (
            <GameContext.Consumer>
                {gameContext => (
                    <Snackbar
                        key={message}
                        autoHideDuration={this.props.autoHideDuration}
                        open={!!message}
                        onClose={this.props.onClose ? this.props.onClose : this.onClose}
                        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                        <Alert onClose={this.props.onClose ? this.props.onClose : this.onClose}
                               severity={this.props.severity ? this.props.severity : Toast.Severity.ERROR}>
                            {message === Toast.MessageType.FEEDBACK ?
                                <span>Did you have fun? Send us your feedback:&nbsp;
                                    <a href="mailto:support@thelunchtrain.com?subject=TLT Feedback">support(at)thelunchtrain.com</a></span>
                                :
                                message
                            }
                        </Alert>
                    </Snackbar>
                )
                }
            </GameContext.Consumer>
        )
    }
}

Toast.propTypes = {
    autoHideDuration: PropTypes.number,
    key: PropTypes.string,
    message: PropTypes.string.isRequired,
    severity: PropTypes.string
}

Toast.defaultProps = {
    autoHideDuration: Toast.DEFAULT_DURATION,
    key: "000",
    message: undefined,
    severity: Toast.Severity.ERROR
}


export default Toast;