import React, {Component} from "react";
import styles from "./GameRow.module.scss";
import "../../../../node_modules/animate.css/animate.css";
import "./animation.css";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import PropTypes from "prop-types";
import GameContext from "../../../context/GameContext";
import UserContext from "../../../context/UserContext";
import User from "../../../User";
import {GameState, PlaceStatus} from "../../../context/GameProvider";
import _ from "lodash";

import * as star_images from "../../../star_icons";
import gameContextService from "../../../service/GameContextService";
import {ANIMATE} from "../../../Animate";
import Badge from "@mui/material/Badge";

class GameRow extends Component {
    static contextType = GameContext;

    static REMOVE_ITEM = "circle-minus";
    static ADD_ITEM = "circle-plus";
    static BRING_BACK_ITEM = "rotate-left";
    static FLASH_ITEM = "flash";

    constructor(props) {
        super(props);

        // reference to Action button
        this.rowAction = React.createRef();

        // game row DOM reference
        this.innerGameRow = React.createRef();
        this.outerGameRow = React.createRef();

        // the action we should animate, if any
        this.animateAction = false;

        this.rowActions = {
            [User.CONDUCTOR]: {
                [`${GameState.NOT_STARTED}_circle-plus`]: this.addPlaceToSelections,
                [`${GameState.NOT_STARTED}_circle-minus`]: this.removePlaceFromSelections,
                [`${GameState.IN_GAME}_circle-minus`]: this.removePlaceFromGame,
                [`${GameState.IN_GAME}_rotate-left`]: this.bringBackPlace,
            },
            [User.PASSENGER]: {
                [`${GameState.NOT_STARTED}_circle-plus`]: function(){},
                [`${GameState.NOT_STARTED}_circle-minus`]: function(){},
                [`${GameState.IN_GAME}_circle-minus`]: this.removePlaceFromGame,
                [`${GameState.IN_GAME}_rotate-left`]: this.bringBackPlace,
            }
        }

        this.state = {
            action: undefined
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.status !== nextProps.status) {
            this.animateAction = this.props.status === PlaceStatus.ACTIVE ? GameRow.REMOVE_ITEM : GameRow.BRING_BACK_ITEM;
        } else {
            this.animateAction = undefined;
        }

        return this.props.status !== nextProps.status;
    }

    /**
     * Adds a Place to the GameContext places in play
     * @param props the Place to add
     * @param gameContext the GameContext
     */
    addPlaceToSelections = (props, gameContext) => {
        gameContext.addPlaceInPlay(props);
    }

    /**
     * Removes a Place from the GameContext places in play
     * @param props the Place to remove
     * @param gameContext the GameContext
     */
    removePlaceFromSelections = (props, gameContext) => {
        gameContext.removePlaceInPlay(props);
    }

    /**
     * Removes a place from the Game Context
     * @param id the id of the place to remove
     */
    removePlaceFromGame = (id) => {
        gameContextService.eliminatePlaceInGame(id);
    }

    /**
     * Brings back a place to the Game Context
     * @param id the id of the place to bring back
     * @param userContext a valid UserContext
     */
    bringBackPlace = (id, userContext) => {
        gameContextService.bringBackPlace(id);
        userContext.removeBringBack();
    }

    /**
     * Returns the action to occur based on the current state of the row (pre-game)
     * @param userContext valid User Context
     * @param gameContext valid Game Context
     * @returns {{fa_icon: (string), action: *, title: string}}
     */
    getConfigAction = (userContext, gameContext) => {
        const user_role = userContext.role;
        const game_state = GameState.NOT_STARTED;
        const id = this.props.id;
        const place = _.find(gameContext.placesInPlay, {"id": id});
        let fa_icon = place ? GameRow.REMOVE_ITEM : GameRow.ADD_ITEM;
        const title = fa_icon === GameRow.ADD_ITEM ? `Add '${this.props.name}' to game` : `Remove '${this.props.name}' from game`;

        if (userContext.role === User.PASSENGER) {
            fa_icon = GameRow.REMOVE_ITEM ? "check" : "thumbs-up";
        }

        return {
            action: this.rowActions[user_role][`${game_state}_${fa_icon}`],
            fa_icon: fa_icon,
            title: title
        };
    }

    /**
     * Returns a FontAwesomeIcon based on the current Place state (before game starts)
     * @param userContext valid User Context
     * @param gameContext valid Game Context
     * @returns {JSX.Element} a FontAwesomeIcon
     */
    getConfigActionButton = (userContext, gameContext) => {
        const clickAction = this.getConfigAction(userContext, gameContext);
        const self = this;

        return (
            <div ref={this.rowAction}
                 className={[styles.action_button, styles[clickAction.fa_icon]].join(" ")}
                 title={clickAction.title}
                 onClick={(event) => {
                     event?.stopPropagation();
                     self.flashButton();
                     clickAction.action(this.props, gameContext)
                 }}>
                <FontAwesomeIcon icon={clickAction.fa_icon}
                                 title={clickAction.title}
                                 className={this.props.index === 0 ? "step-game-row-action" : ""} />
            </div>
        );
    }

    /**
     * Returns the action to occur based on the current state of row (in-game)
     * @param userContext valid User Context
     * @param gameContext valid Game Context
     * @returns {{fa_icon: (string), action: *, title: string}}
     */
    getInGameAction = (userContext, gameContext) => {
        const user_role = userContext.role;
        const game_state = GameState.IN_GAME;
        const fa_icon = this.props.status === PlaceStatus.ACTIVE ? GameRow.REMOVE_ITEM : GameRow.BRING_BACK_ITEM;

        // action to take on button click
        let clickAction = this.rowActions[user_role][`${game_state}_${fa_icon}`];

        // disable the button if it's not my turn or the user took an action
        let className = styles.action_button + " " + styles[fa_icon];
        let isDisabled = false;
        const bringBackDisabled = fa_icon === GameRow.BRING_BACK_ITEM && userContext.bringBacksAvailable <= 0;

        if(gameContext.currentPlayerId !== userContext.userId ||
            gameContext.isPlayerAction || bringBackDisabled) {
            isDisabled = true;
        }

        className += isDisabled ? " " + styles.disabled : "";

        // handler for clicking on button when it is not my turn or on bring back with none left
        clickAction = isDisabled ? function() {
            if(gameContext.currentPlayerId !== userContext.userId) {
                gameContext.setToastMessage("It is not your turn!");
            } else {
                gameContext.setToastMessage("You do not have any bring backs left!");
            }
        } : clickAction;

        // title for each action
        const title = fa_icon === GameRow.REMOVE_ITEM ? `Remove '${this.props.name}' from game` :
            bringBackDisabled ? "You are out of bring backs" : `Bring back '${this.props.name}'`;

        return {
            action: clickAction,
            fa_icon: fa_icon,
            title: title,
            isDisabled: isDisabled,
            clazz: className
        }
    }

    /**
     * Returns a FontAwesomeIcon based on the current Place state (in game)
     * @param userContext valid User Context
     * @param gameContext valid Game Context
     * @returns {JSX.Element} a FontAwesomeIcon
     */
    getInGameActionButton = (userContext, gameContext) => {
        const fa_icon = this.props.status === PlaceStatus.ACTIVE ? GameRow.REMOVE_ITEM : GameRow.BRING_BACK_ITEM;
        const bringBackDisabled = fa_icon === GameRow.BRING_BACK_ITEM && userContext.bringBacksAvailable <= 0;
        const clickAction = this.getInGameAction(userContext, gameContext);

        const self = this;
        return (
            <div ref={this.rowAction}
                 className={clickAction.clazz}
                 title={clickAction.title}
                 onClick={(event) => {
                     event?.stopPropagation();
                     if(!clickAction.isDisabled) {
                         self.animateRow(fa_icon, false);
                     }
                     clickAction.action(this.props.id, userContext);
                 }}>
                <FontAwesomeIcon icon={fa_icon}
                                 disabled={gameContext.currentPlayerId !== userContext.userId}
                                 className={this.props.index === 0 ? "step-game-row-action" : ""} />
                {bringBackDisabled && <FontAwesomeIcon className={styles.x_mark} icon="xmark" />}
                {fa_icon === GameRow.BRING_BACK_ITEM && userContext.bringBacksAvailable > 0 &&
                    <Badge badgeContent={userContext.bringBacksAvailable}
                           color="info"
                           className={styles.bringbacks} />
                }
            </div>
        );
    }

    /**
     * Flashes the action button
     */
    flashButton = (action) => {
        const button = this.rowAction.current;
        const self = this;
        button.classList.add(ANIMATE.ANIMATED);
        button.classList.add(ANIMATE.HEARTBEAT);
        button.addEventListener(ANIMATE.EVENT_ANIMATION_END, () => {
            button.classList.remove(ANIMATE.ANIMATED);
            button.classList.remove(ANIMATE.HEARTBEAT);

            if(action) {
                self.animateRow(action, false);
            }
        });
    }

    /**
     * Animates the row given the action to be clicked
     * @param action the action to be clicked (fa_icon)
     * @param scrollTo smooth transition for scrolling to removed row
     */
    animateRow = (action, scrollTo) => {
        const innerDom = this.innerGameRow.current;
        if(scrollTo) {
            innerDom.scrollIntoView({behavior: "smooth", block: "center"});
        }
        innerDom.classList.add(ANIMATE.ANIMATED);

        switch(action) {
            case GameRow.REMOVE_ITEM:
                innerDom.classList.add(ANIMATE.REMOVE);
                break;
            case GameRow.BRING_BACK_ITEM:
                innerDom.classList.add(ANIMATE.BRING_BACK);
                break;
            case GameRow.FLASH_ITEM:
                innerDom.classList.add(ANIMATE.FLASH);
                break;
        }

        // remove animation classes on animation end
        innerDom.addEventListener(ANIMATE.EVENT_ANIMATION_END, () => {
            innerDom.classList.remove(ANIMATE.ANIMATED);
            innerDom.classList.remove(ANIMATE.BACK_OUT_LEFT);
            innerDom.classList.remove(ANIMATE.BOUNCE_IN_LEFT);
            innerDom.classList.remove(ANIMATE.BRING_BACK);
            innerDom.classList.remove(ANIMATE.FLASH);
            innerDom.classList.remove(ANIMATE.REMOVE);
        });
    }

    render() {
        const distInMiles = Math.round(((this.props.distance * 0.000621371192) + Number.EPSILON) * 100) / 100;
        const distStr = `${distInMiles} mi`;
        const categoryString = this.props.category ? this.props.category.join(", ") : "";
        const categoryIcon = gameContextService.getPlaceIcon(this.props.category);

        return (
            <GameContext.Consumer>
                {gameContext => (
                    <UserContext.Consumer>
                        {userContext => {
                            if(this.animateAction && gameContext.gameState === GameState.IN_GAME) {
                                if(!gameContext.isCurrentPlayer(userContext)) {
                                    this.animateRow(GameRow.FLASH_ITEM, true);
                                } else {
                                    this.animateRow(this.animateAction, false);
                                }
                            }
                            return (
                                <div ref={this.outerGameRow} className={this.props.index === 0 ? "step-game-row" : ""}>
                                    <div className={styles.game_row} ref={this.innerGameRow}>
                                        {/* icon */}
                                        <img className={styles.place_icon}
                                             title={categoryString}
                                             src={categoryIcon}
                                             alt={categoryString}
                                             onClick={() => gameContextService.openUrl(this.props.url)} />

                                        {/* place row */}
                                        <div className={styles.place_overview}>
                                            {/* name */}
                                            <div className={styles.place_name}
                                                 title={this.props.name}
                                                 id={this.props.id}
                                                 onClick={() => gameContextService.openUrl(this.props.url)}>
                                                {this.props.name}

                                                {/* url */}
                                                {this.props.url &&
                                                    <div className={styles.place_url} title="Click to view website">
                                                        <FontAwesomeIcon icon={"arrow-up-right-from-square"}/>
                                                    </div>}
                                            </div>

                                            {/* rating */}
                                            <img className={styles.place_rating}
                                                 title={"Rating: " + this.props.rating}
                                                 src={star_images[("star_" + this.props.rating).replace(".", "_")]}
                                                 alt={"Rating: " + this.props.rating}/>

                                            {/* category */}
                                            <div className={styles.place_category} title={categoryString}>{categoryString}</div>

                                            {/* address */}
                                            <div className={styles.place_location}
                                                 title={this.props.location.display_address.join("\n")}>{this.props.location.display_address[0]}</div>

                                        </div>
                                        <div className={styles.place_additional}>
                                            {/* distance */}
                                            {!distStr.includes("0 mi") &&
                                                <div className={styles.place_distance}
                                                     title={"Distance: " + distStr}>{distStr}</div>
                                            }

                                            {/* price */}
                                            <div className={styles.place_cost} title={"Price: " + this.props.price}>
                                                {this.props.price.split("").map((x, i) =>
                                                    <FontAwesomeIcon key={i} icon={"dollar-sign"}/>
                                                )}
                                            </div>
                                        </div>
                                        <div className={styles.divider}/>
                                        {gameContext.gameState === GameState.NOT_STARTED ?
                                            this.getConfigActionButton(userContext, gameContext)
                                            :
                                            this.getInGameActionButton(userContext, gameContext)
                                        }
                                    </div>
                                    <div className={styles.game_row_border}/>
                                </div>
                            )
                        }
                        }
                    </UserContext.Consumer>
                )
                }
            </GameContext.Consumer>
        );
    }
}

GameRow.propTypes = {
    category: PropTypes.array.isRequired,
    disable: PropTypes.bool,
    id: PropTypes.string.isRequired,
    index: PropTypes.number,
    name: PropTypes.string.isRequired,
    rating: PropTypes.number,
    location: PropTypes.object,
    distance: PropTypes.number,
    price: PropTypes.string,
    url: PropTypes.string
}

GameRow.defaultProps = {
    category: ["Bars", "American (new)", "Breakfast & Brunch"],
    id: "xxx",
    index: "0",
    name: "The Liberty Tavern",
    rating: 4,
    location: {
        address1: "3195 Wilson Blvd",
        address2: "",
        address3: "",
        city: "Arlington",
        country: "US",
        display_address: [
            "3195 Wilson Blvd",
            "Arlington, VA 22201"
        ],
        state: "VA",
        zip_code: "22201"
    },
    distance: 0,
    price: "$$",
    url: ""
}

export default GameRow;