import React, {Component} from "react";
import {Skeleton} from "@mui/material";
import GameContent from "../../components/Game/GameContent/GameContent";
import common_styles from "../../components/Common/common.module.scss";
import GameContext from "../../context/GameContext";
import InGameHeader from "../../components/Game/InGameHeader/InGameHeader";
import Toast from "../../components/Common/Toast/Toast";
import JoyRide, {ACTIONS, EVENTS, STATUS} from "react-joyride";
import {TOUR_GUIDE_OPTIONS} from "../../components/Common/tour_guide";

class InGame extends Component {
    static contextType = GameContext;

    constructor(props) {
        super(props);

        this.state = {
            isMounted: false,
            isTourOpen: false,
            stepIndex: 0
        }
    }

    componentDidMount() {
        this.setState({
            isMounted: true
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        Object.entries(this.props).forEach(([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        );
        if (this.state) {
            Object.entries(this.state).forEach(([key, val]) =>
                prevState[key] !== val && console.log(`State '${key}' changed`)
            );
        }
    }

    openTour = () => {
        this.setState({
            isTourOpen: true
        });
    }

    handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            this.setState({
                stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)
            });
        } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            this.setState({
                isTourOpen: false,
                stepIndex: 0
            });
        }
    };

    render() {
        return (
            this.state.isMounted ?
                <React.Fragment>
                    <div className={common_styles.game_container}>
                        <JoyRide
                            steps={this.TOUR_STEPS}
                            stepIndex={this.state.stepIndex}
                            callback={this.handleJoyrideCallback}
                            continuous
                            showSkipButton
                            run={this.state.isTourOpen}
                            locale={{last: "Close"}}
                            styles={{options: TOUR_GUIDE_OPTIONS}}
                        />
                        {this.context.toastMessage &&
                            <Toast message={this.context.toastMessage}
                                   severity={this.context.toastSeverity}
                                   autoHideDuration={this.context.toastDuration}/>}
                        <InGameHeader openTour={this.openTour} />
                        <GameContent />
                    </div>
                </React.Fragment>
                :
                <Skeleton variant="rectangular" height="100%" />
        );
    }

    TOUR_STEPS = [
        {
            target: ".step-player-turn",
            content: "Whose turn is it?",
        },
        {
            target: ".step-timer",
            content: "Time remaining for passenger to make a move"
        },
        {
            target: ".step-game-row-action",
            content: "When it's your turn, click on the '-' button to remove this restaurant from the game. Click on the " +
                "undo button to bring this restaurant back into the game!"
        },
        {
            target: ".step-restaurants-header",
            content: "The last restaurant remaining is the next stop for the train!"
        }
    ]
}

InGame.defaultProps = {
    displayName: "InGame"
}

export default InGame;