// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare";
import {faCalendarDays} from "@fortawesome/free-solid-svg-icons/faCalendarDays";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons/faCaretDown";
import {faCaretRight} from "@fortawesome/free-solid-svg-icons/faCaretRight";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faCircleMinus} from "@fortawesome/free-solid-svg-icons/faCircleMinus";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons/faCirclePlus";
import {faCircleQuestion} from "@fortawesome/free-solid-svg-icons/faCircleQuestion";
import {faClock} from "@fortawesome/free-solid-svg-icons/faClock";
import {faDollarSign} from "@fortawesome/free-solid-svg-icons/faDollarSign";
import {faEye} from "@fortawesome/free-solid-svg-icons/faEye";
import {faFilm} from "@fortawesome/free-solid-svg-icons/faFilm";
import {faForwardStep} from "@fortawesome/free-solid-svg-icons/faForwardStep";
import {faHomeLgAlt} from "@fortawesome/free-solid-svg-icons/faHomeLgAlt";
import {faLink} from "@fortawesome/free-solid-svg-icons/faLink";
import {faLocationArrow} from "@fortawesome/free-solid-svg-icons/faLocationArrow";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons/faLocationDot";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons/faMagnifyingGlass";
import {faPassport} from "@fortawesome/free-solid-svg-icons/faPassport";
import {faUtensils} from "@fortawesome/free-solid-svg-icons/faUtensils";
import {faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion";
import {faRotateLeft} from "@fortawesome/free-solid-svg-icons/faRotateLeft";
import {faShop} from "@fortawesome/free-solid-svg-icons/faShop";
import {faStore} from "@fortawesome/free-solid-svg-icons/faStore";
import {faTicket} from "@fortawesome/free-solid-svg-icons/faTicket";
import {faThumbsUp} from "@fortawesome/free-solid-svg-icons/faThumbsUp";
import {faTrainSubway} from "@fortawesome/free-solid-svg-icons/faTrainSubway";
import {faTriangleExclamation} from "@fortawesome/free-solid-svg-icons/faTriangleExclamation";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faXmark} from "@fortawesome/free-solid-svg-icons/faXmark";

// all fontawesome icons must be directly added here if you want to access them elsewhere
library.add(
    faAngleRight,
    faArrowUpRightFromSquare,
    faCalendarDays,
    faCaretDown,
    faCaretRight,
    faCheck,
    faCircleMinus,
    faCirclePlus,
    faCircleQuestion,
    faClock,
    faDollarSign,
    faFilm,
    faEye,
    faForwardStep,
    faHomeLgAlt,
    faLink,
    faLocationArrow,
    faLocationDot,
    faMagnifyingGlass,
    faQuestion,
    faPassport,
    faRotateLeft,
    faShop,
    faStore,
    faTicket,
    faThumbsUp,
    faTrainSubway,
    faTriangleExclamation,
    faUser,
    faUtensils,
    faXmark
);